#pdf-document {
  padding: 10px;
  .group_table {
    .ant-table-wrapper .ant-spin-nested-loading {
      margin-top: 0;
    }
  }
  .ant-table-wrapper .ant-spin-nested-loading {
    min-height: 15rem;
  }
  .echarts-for-react {
    margin: 50px auto 70px auto;
  }

  .nosearch-container {
    display: flex;
    width: 100%;
    height: 30vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .org_charts {
    display: flex;
    .echarts-for-react {
      width: 40%;
    }
  }
}
