.view-channel-display {
  // height: calc(100vh - 33rem);
  width: 100%;

  &-file {
    background: #f5f5f5;
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
    object-fit: cover;
  }
}


.view-channel-descption {
  max-height: 300px;
  min-height: 100px;
  overflow-y: auto;
  padding: 15px;
  border: 1px solid #dfdfdf;
  box-shadow: 0 0 2px 1px #f2f2f2;
  border-radius: 15px;

  >p {
    margin-bottom: 0;
  }
}

.ssl-channel-description {
  margin: 15px 0;
  height: fit-content;
  text-transform: uppercase;
}