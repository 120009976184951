.alert_message {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 104, 255, 0.2);
  z-index: 100;
}

.wrap_modal_tabs {
  padding: 0px 0px;
}

.wrap_btn_actions {
  margin-top: 10px;
  height: 80px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 10px;

  .action_button {
    border-radius: 25px;
    width: 150px;
  }
}

/*MAHESH_ADDED*/

.bg-modal {
  background: #fff;
  margin: 0px -44px;
  padding: 20px 44px;

  .card__content {
    padding: 1.5rem 2rem;
    background: transparent;
  }
}

.audio-img-warp {
  width: 45px;
  margin-right: 10px;
  float: left;
  text-align: center;

  img {
    display: block;
    margin: auto;
    max-width: 100%;
  }
}

.toolbar__item-icon {
  line-height: 1.2;
}

.wrap-video-icons {
  position: relative;
}

.play-icon-inside-rectangle {
  position: absolute;
  top: 8px;
  left: 11px;
}

.text-icon-inside-rectangle {
  position: absolute;
  top: 6px;
  left: 8px;
}

.create-annotation {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  margin-left: 1px;
  text-transform: capitalize;
  color: #000000;
}

.text-wrapper-block {
  padding-left: 7px;
}

.custom-ant-modal .ant-modal-content {
  padding: 25px 20px;

  .ant-modal-footer {
    text-align: right;
    margin-top: 9px;
  }

  .ant-modal-header {
    border-bottom: 0 solid #e8e8e8;
    padding-top: 0;
  }

  .ant-modal-title {
    color: rgb(103, 104, 108);
    font-weight: normal;
  }
}

.landscape-portrait-button {
  margin-bottom: 10px;
}

.wrap-btn-actions {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
