.card-audio {
  box-shadow: none;
  cursor: pointer;
  position: relative;

  .card__content {
    color: #a2afbe;

    .ant-btn {
      position: absolute;
      right: -15px;
      border: none;
      display: none;
      background: transparent;

      i {
        transform: rotate(90deg);
        color: #2699fb;
      }

      ul {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &:hover {
    box-shadow: none;

    .ant-btn {
      display: block;
    }
  }

  .card__title {
    color: #131311;
    margin-bottom: 0px;
  }

  .card__audio-thumb {
    border: 1.5px solid #e3e3e3;
    position: relative;
    background: #fff;
    border-radius: 8px;
    &:hover {
      border: 0.51px solid #8c98af;
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
    }
  }
}

.media-page__item-on-select {
  .card__audio-thumb::after {
    background: transparent;
    z-index: 1;
  }
}

.card_on-select {
  background: rgba(233, 239, 246, 0.3);
  padding: 5px;
  border-radius: 8px;
  border: 1.5px solid #e3e3e3;

  .card__audio-thumb {
    background: #fff;
    border: none;

    i {
      z-index: 3;
    }

    &::after {
      background: #fff;
    }

    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .card__content {
    background: #d5ecff;

    .ant-btn {
      background: #d5ecff;
      display: none;
    }
  }
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0px;

  &-active {
    color: #136cfb !important;
    font-weight: 500;
  }
}

.audio-detail {
  margin-top: 30px;

  strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #131311;
    margin-bottom: 10px;
    display: block;
  }

  p {
    font-size: 12px;
    line-height: 15px;
    color: #404756;
  }

  button {
    margin-top: 20px;
    padding: 7px 20px;
    border: none;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
  }
}
