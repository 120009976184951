.button-create {
  z-index: 1;
  top: 0;
  right: 0;
  position: absolute;
  float: right;

  &:hover {
    background: #1dba61;
    color: white;
  }
}

.ant-modal-close-x {
  position: absolute;
  right: 0;
}

.ant-tabs {
  width: 100%;
  overflow: initial;
}

.tab-nav-margin .ant-tabs-bar {
  margin-bottom: 0px;
}

.custom-ant-modal .ant-modal-content {
  border-radius: 10px;

  .ant-modal-header {
    border-bottom: 0px solid #e8e8e8;
  }

  .ant-modal-body {
    padding: 0px 24px;
    position: relative;
  }

  .ant-modal-footer {
    padding: 0px 16px;
    border-top: none;
  }
}

.bg-modal-left {
  background: #fff;
  margin: 0px -44px;
  padding: 0px 44px;

  .card__content {
    padding: 1.5rem 2rem;
    background: transparent;
  }
}

.canva-container {
  width: 100%;
  height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
  border: 1px dotted black;
}

.generate_images {
  &_container {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    // display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    // justify-content: space-around;
    // width: 100%;
    height: auto;
    padding: 3rem;
    gap: 2rem;
    margin-top: 1rem;
    background: #e8e8e8;
    border-radius: 15px;

    img {
      cursor: pointer;
      object-fit: cover;
      width: 100%;

      &:hover {
        opacity: 0.9;
        transform: translateZ(2px);
      }
    }

    .selected {
      img {
        border-color: #1dba61;
        border-style: double;
      }

      &_badge {
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem 2rem;
        background: #1dba61;
        color: #fff;
        border-bottom-right-radius: 15px;
      }
    }

    .variations {
      position: absolute;
      right: 0;
      top: 0;
      background: rgb(238, 238, 238);
      border-radius: 5px;
      margin: 10px;
      cursor: pointer;
      z-index: 999;
      text-decoration: none;
      border-radius: 60px;
      height: 30px;
      padding: 0.5rem 1.1rem;
      overflow: hidden;
      width: auto;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 1.1rem;
      max-width: 32px; /** I'm animating max-width because width needs to be auto, and auto can't be animated **/
      -webkit-transition: max-width 0.5s;
      transition: max-width 0.5s;
      box-shadow: 0 1px 2px darkslategray;
      &:hover {
        max-width: 300px;
        background: #0068ff;
        color: #e8e8e8;
      }

      &:active {
        background-color: #48abe0;
        box-shadow: 0 0 2px darkslategray;
        transform: translateY(2px);
      }

      .icon {
        display: flex;
        font-size: 2rem;
        margin-bottom: 4px;
        align-items: center;
        font-weight: bold;
      }

      .text {
        white-space: nowrap;
      }
    }
  }

  &_item {
    position: relative;
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    height: 100%;
    background: rgba(255, 255, 255, 0.527);
    display: flex;
    align-items: center;

    img {
      max-height: 100%;
    }
  }
}
.genera_images_label {
  margin-top: 2rem;
}

.variation_search_container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  background: #ebebeb;
  border-radius: 10px;

  padding: 3rem;

  .select-count {
    height: 4rem;
    width: 30rem;
  }
}

.variation_insert {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #0068ff;
  color: white;
  height: 5rem;
  cursor: pointer;
  gap: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background: #088438;
    box-shadow: 0 0 2px white;
  }
}
