.org-group {
  .org-group-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .org-group-list {
      .org-group-btn {
        display: flex;
        align-items: center;
        padding: 0.5rem 2rem;
        border: 1.5px solid #e3e3e3;
        transition: border-color 0.2s;
        cursor: pointer;
        border-radius: 30px;
        &.active {
          color: #5057d5;
          border: 1.5px solid #5057d5;
        }
        .org-group-initial {
          background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4rem;
          height: 4rem;
          margin-right: 1.6rem;
          border-radius: 100%;
        }
      }
    }
  }
}
