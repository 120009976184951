.character-card {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    border-color: #1890ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &.selected {
    border-color: #1890ff;
    box-shadow: 0 0 0 2px #1890ff;
  }
}

.character-image-container {
  width: 100%;
  padding-top: 100%; // Aspect ratio 1:1
  background-color: #f0f0f0;
  background-size: cover;
  background-position: center;
  position: relative;

  .fallback-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #8c8c8c;
    font-size: 14px;
    font-weight: bold;
  }
}

.character-details {
  padding: 16px;
  text-align: center;
  width: 100%;

  .character-name {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
  }

  .character-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;

    .character-tag {
      font-size: 12px;
      padding: 2px 8px;
      border-radius: 4px;
    }
  }
}

.selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1890ff80;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 8px;
  pointer-events: none;
  text-transform: uppercase;
}
