@import '../../../../styles/variables';

.contain {
  position: relative;
  flex: 1;
  height: 90%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.media_container {
  position: relative;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5),
    inset 0px 1px 3px rgba(0, 0, 0, 0.5);
  height: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #777777;
  text-align: center;
  overflow: hidden;
  color: #fff;
}

.download_icon {
  position: absolute;
  justify-content: center;
  right: 1rem;
  top: 3rem;
  border-radius: 50%;
  background: #2e4463;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  z-index: 100;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.delete_icon {
  position: absolute;
  justify-content: center;
  right: 1rem;
  top: 8rem;
  border-radius: 50%;
  background: #2e4463;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  z-index: 100;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

.media_detail {
  max-height: 20%;
  padding-bottom: 20px;
}

.media_preview {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  .ant-carousel {
    width: 92% !important;
  }

  &_key {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #2e4463;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    justify-content: center;
    display: flex;

    i {
      font-size: 18px;
    }

    &:hover {
      box-shadow: 0 0 2px 2px white;
    }

    &:active {
      box-shadow: 0 0 2px 2px rgb(225, 225, 225);
    }
  }

  .left_key {
    left: 1rem;
  }

  .right_key {
    right: 1rem;
  }
}

.slider_container {
  white-space: nowrap;
  padding: 2rem;
  width: 30%;
  height: 10rem;
  overflow: hidden;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  /* Firefox 64 */
  -ms-overflow-style: none;

  /* IE 11 */
  &::-webkit-scrollbar {
    /** Webkit */
    display: none;
  }
}

.slide_info {
  display: inline-block;
  vertical-align: middle;
  width: 8rem;
  height: 5rem;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  transform-origin: center center;

  :first-child {
    margin-left: 0px;
  }

  :last-child {
    margin-right: 0px;
  }

  &:hover {
    cursor: pointer;
  }

  i {
    display: flex;
    width: 8rem;
    height: 4.5rem;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    background-color: #333333;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.active {
  border: 3px solid white;
  box-shadow: 0 0 10px rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 10px #fff;
}

.file_name {
  color: #0068ff;
  font-weight: bold;
  font-size: 14px;
  padding-top: 57px;
  display: flex;
  gap: 2rem;
  align-items: center;

  i {
    border: 1px solid #0066ff93;
    border-radius: 50%;
    padding: 0.5rem;
  }
}

.file_detail {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-top: 11px;
}

.media_tag {
  position: relative;
  display: inline-flex;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  background: #ececec;
  border-radius: 2rem;
  text-transform: uppercase;
}

.extension {
  padding-left: 10px;
  font-weight: 300;
  font-size: 11px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #777777;
}

.size {
  padding-left: 10px;
  font-weight: 300;
  font-size: 11px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #777777;
}

.topic {
  font-weight: bold;
  padding-top: 18px;
  font-size: 14px;
  color: #777777;
}

.file_topic {
  padding: 8px 0px;

  span {
    padding-right: 11px;
    color: #0068ff;
    font-size: 12px;
  }
}

.description {
  font-weight: bold;
  font-size: 14px;
  color: #777777;
  padding-top: 57px;
}

.media_des {
  font-weight: normal;
  font-size: 12px;
  color: #777777;
  margin-top: 10px;
  padding: 8px 0px;
  // height: 31px;
}

.des_click {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.contain_textarea {
  position: relative;
}

.des_container {
  padding: 8px 0px;
}

.keywords {
  font-weight: bold;
  padding-top: 18px;
  font-size: 14px;
  color: #777777;
}

.file_keywords {
  padding: 8px 0px;

  span {
    padding-right: 11px;
    color: #0068ff;
    font-size: 12px;
  }
}

.empty_label {
  height: 20px;
  font-weight: normal;
  font-size: 12px;
  color: #777777;
}

.uploaded_by {
  padding: 8px 0px;
  font-weight: bold;
  font-size: 14px;
  color: #777777;
  display: flex;
  flex-direction: column;

  #uploader {
    font-size: 14px;
    padding-top: 5px;
    font-weight: normal;
  }
}

.custom-select-tag {
  >div {
    padding: 5px 5px 10px 5px;

    &:focus,
    &:active {
      box-shadow: none;
      border-color: #d9d9d9;
    }
  }

  ul {
    li {
      &.ant-select-selection__choice {
        background: map-get($colors, white);
        border: 1px solid map-get($colors, blue);
        border-radius: 13px;
        color: map-get($colors, blue);
        padding: 0 10px;
        margin-left: 0;

        >span {
          display: none;
        }
      }
    }
  }
}

.hoverStyle {
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}

// Hide dropdown for select tags
.disable-select-dropdown {
  ul {
    display: none;
  }
}

.wrapper {
  width: 100%;
  flex: 1;
  display: flex;
}

.left_content {
  margin-left: 8%;
  margin-right: 2.5%;
  padding-left: 1%;
  padding-right: 1%;
  width: 25%;
}

.right_content {
  margin-left: 2.5%;
  margin-right: 8%;
  padding-left: 1%;
  padding-right: 1%;
  width: 50%;
}

.modal_wrapper {
  position: absolute;
  top: 16.5rem;
  right: 2.5rem;
}

.download-modal {
  margin-top: 1rem;
  padding: 0.5rem 0.5rem;
  background-color: white;
  width: 200px;
  border-radius: 5px;
}

.download_title {
  padding: 0.5rem 1rem;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  text-transform: capitalize;
}

.download_des {
  padding: 0.5rem 1rem;
  font-size: 12px;
  align-items: center;
  text-transform: capitalize;
  color: #777777;

  &:hover {
    cursor: pointer;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  position: inherit;
  right: 5px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  /* Changed from border-top */
  position: inherit;
  right: 5px;
}

.error_message {
  color: #ff4b55;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.disabled-checkbox>.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #858585 !important;
}

.disabled-checkbox>.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white;
}

// .ant-checkbox-disabled .ant-checkbox-inner {
//   width: 25px;
//   height: 25px;
//   background-color: gray;
//   border-color: gray;
// }