h1 {
  font-weight: 300;
  font-size: 1.8rem;
  margin: 0;
}

h2 {
  font-weight: 600;
  font-size: 1.6rem;
  margin: 0;
}

h3 {
  font-weight: 700;
  font-size: 1.4rem;
  margin: 0;
}

h4 {
  font-weight: 300;
  font-size: 1.2rem;
  margin: 0;
}
