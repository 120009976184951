.ant-tabs {
  width: 100%;
  overflow: initial;
}

.ant-modal-close-x {
  position: absolute;
  right: 0;
}

.tab-nav-margin .ant-tabs-bar {
  margin-bottom: 0px;
}

.custom-ant-modal .ant-modal-content {
  border-radius: 10px;

  .ant-modal-footer {
    padding: 0px 16px;
    border-top: none;
  }

  .ant-modal-header {
    border-bottom: 0px solid #e8e8e8;
  }

  .ant-modal-body {
    padding: 0px 24px;
    position: relative;
  }
}

.bg-modal-left {
  margin: 0px -45px;
  padding: 0px 44px;

  .card__content {
    padding: 1.5rem 2rem;
    background: transparent;
  }
}

/*.bpYVBQ {
  margin-left: 0px;
  color: #c0c0c0;
  font-size: 73px;
}*/
