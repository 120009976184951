.listener-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  h3 {
    color: black;
    font-weight: bold;
    margin: 2rem;
  }

  &__user {
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px;
    width: 28rem;
    /* Adjust width as needed */
    background-color: #ffffff;
    /* Background color */
    border: 1px solid black;
    /* Border color */
    border-radius: 50px;
    overflow: hidden;
    /* Clip the inner content */
  }

  &__user img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid black;
  }

  &__user span {
    margin-left: 3rem;
    margin-right: 3rem;
    color: black;
    font-weight: 600;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 3rem;

    &_kick {
      overflow: hidden;
      border: 1px solid #ff0000;
      gap: 0.3rem; // Space between the icon and the text
      color: #fcfcfc;
      background-color: #ff0000;

      &:hover {
        border: 1px solid #ff0000;
        color: #fcfcfc;
        background-color: #ff0000;
      }
    }

    &_request {
      overflow: hidden;
      border: 1px solid #FFA800;
      gap: 0.3rem; // Space between the icon and the text
      color: #fcfcfc;
      background-color: #FFA800;

      &:hover {
        color: #fcfcfc;
        border: 1px solid #FFA800;
        background-color: #FFA800;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem;
      width: 17rem;
      /* Adjust width as needed */
      height: 3.5rem;
      border-radius: 50px;
      overflow: hidden;
      /* Clip the inner content */
    }
  }
}