.wrap_date_picker {
  align-items: flex-end !important;
}

.eventDatePicketContainer {
  position: relative;
  .ant-calendar-picker .anticon {
    display: none;
  }
  .publishEventCalendarButton {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 10px;
    margin-right: 2px;
    cursor: pointer;

    &:hover {
      background: rgba(128, 128, 128, 0.107);
    }

    &:focus {
      background: inherit;
    }
  }
}
.scheduling-row {
  min-width: 50vw;
  height: 50vh;
  display: flex;
  gap: 1rem;

  .rbc-day-bg {
    cursor: pointer;

    &:hover {
      background: rgba(0, 145, 255, 0.066);
    }
  }
  .rbc-event,
  .rbc-day-slot .rbc-background-event {
    background-color: #2d6ff2;
  }

  .rbc-today {
    background-color: rgba(57, 224, 57, 0.321);
  }

  .day-calendar {
    .rbc-btn-group {
      display: none !important;
    }
  }

  .rbc-date-cell {
    position: relative;
  }
}

.calendar-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.timeslots-container {
  height: 100%;
  border: 1px solid #dfdfdf;
  padding: 1rem 0 3rem 0;
  .timeslots {
    height: inherit;
    overflow: auto;
    &-title {
      padding: 0 1rem 1rem 1rem;
      border-bottom: 1px solid #dfdfdf;
      color: #2d6ff2;
    }
  }
  .timeslot {
    min-width: 10rem;
    display: grid;
    grid-template-columns: 0.3fr 1fr;

    &:not(:last-child) {
      border-bottom: 1px solid #dfdfdf;
    }
    .time {
      padding: 1rem;
      font-weight: bold;
      border-right: 1px solid #dfdfdf;
      white-space: nowrap;
    }

    .selection {
      display: flex;
      align-items: center;
      justify-content: left;
      padding-left: 2rem;
      width: 100%;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: white;
        border-color: white;
      }

      .ant-checkbox-checked .ant-checkbox-inner:after {
        border-color: #2d6ff2 !important;
      }
    }

    .selected {
      background: #2d6ff2;
    }
  }
}

.custom-calendar {
  display: flex;
  gap: 2rem;
  align-items: flex-end;
}

.selected-custom-day {
  &::before {
    content: '';
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.selected-day {
  border: 2px solid red;
  border-radius: 50%;
  font-weight: bold;
  a {
    // color: red;
  }
  // border-radius: 50%;
}

.slot {
  &-display {
    margin-top: 2rem;
  }

  &-day {
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 5px;

    &::before {
      content: '\2022';
      margin-right: 4px;
    }

    &-tag {
      padding: 5px 10px;
    }
  }
}
