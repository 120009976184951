.shot-list-shots {
  .shot-list-header {
    margin-bottom: 20px;

    h1 {
      color: #333;
      font-size: 18px;
      font-weight: 600;
    }

    p {
      color: #666;
      font-size: 14px;
    }
  }

  .scene-container {
    margin-bottom: 30px;
    border: 1px solid #f0f0f0;
    padding: 20px;
    background-color: #fafafa;

    .scene-name-input {
      margin-bottom: 15px;
      font-weight: bold;

      input {
        font-size: 16px;
      }
    }

    h2.scene-name {
      font-size: 14px;
      margin-bottom: 5px;
      color: mediumslateblue;
    }

    .shot-list-shot {
      margin-bottom: 10px;
    }
  }
}
