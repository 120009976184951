@import "../../../../styles/variables";

.add-topic-card {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  &__thumb {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid map-get($colors, darkGreyTwo);

    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__select-topic {
    width: 100%;
    margin: 10px 0;
  }

  .error_message {
    color: #FF4B55;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}
