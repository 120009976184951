.el-placeholder {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    font-size: 2.4rem;
    margin-right: 1rem;
    color: #0068ff;
  }

  &__text {
    font-size: 1.6rem;
  }
}

.preview {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  &_image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &_video {
    position: relative;
  }

  &_annotation {
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &_chat {
    padding: 2rem;
    overflow: scroll;
  }

  .ql-editor {
    padding: 0;
  }
}

.embed-container {
  width: 100%;
  height: 100%;
  background: #777777;

  iframe,
  object,
  embed {
    width: 100%;
    height: 100%;
  }
}

.container {
  max-height: 370px !important;
  justify-content: center !important;
  overflow: hidden !important;
}

.text-content {
  color: #fff;
  height: 100%;
  text-align: left;
  margin-top: 20px;
}
