.tree {
  &__node {
    border-radius: 15px;
    padding: 4rem;
    border: 2px solid rgba(126, 120, 120, 0.473);
    position: relative;

    &:hover &-popup {
      display: grid;
    }

    &-popup {
      position: absolute;
      display: none;
      place-items: center;
      bottom: -4rem;
      left: 0;
      height: 4rem;
      width: 100%;
      border-radius: 5px;
      border: 2px solid rgba(126, 120, 120, 0.473);
    }
  }
}

.main-node {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  border: 2px solid black;
  position: relative;
  z-index: 55;
  font-size: larger;

  .deadend {
    width: 100%;
    height: 40px;
    display: none;
    z-index: 56;
    position: absolute;
    bottom: 0;
    left: 0;
    place-items: center;
    color: white;
    cursor: pointer;
  }

  &:hover .deadend {
    display: grid;
  }
}

.maze-tree-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .Canvas-module_container__22cN4.Canvas-module_pannable__1d7xd {
    scrollbar-width: none;
  }
  &__controls {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 2rem;
    margin-left: auto;
    width: max-content;
    transition: opacity 0.2s, visibility 0.2s;
    outline: 1px solid #e9e9e9;
    border-radius: 50px;
    background: white;
    margin-top: -4rem;

    & > * {
      cursor: pointer;
      padding: 0 1rem;
      font-size: 1.5rem;
      color: #5057d5;
    }

    & > *:not(:last-child) {
      border-right: 1px solid #e9e9e9;
    }
  }

  &__bottom {
    color: #5057d5;
    position: absolute;
    left: 0;
    bottom: 0;

    i {
      margin: 1rem;
      background: white;
      padding: 1rem;
      border-radius: 50%;
      outline: 2px solid #e9e9e9;
      cursor: pointer;
    }
  }

  &__legend {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;

    &__container {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
    }

    &__type {
      width: 100%;
      font-weight: 600;
      font-size: 1.1rem;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 1rem;

      &__shape {
        width: 2.5rem;
        height: 1.75rem;

        &.blue {
          background: #5057d5;
          outline: 1px solid #000;
        }

        &.green {
          background: #00cc00;
          outline: 1px solid #000;
        }

        &.white {
          background: white;
          outline: 1px solid #000;
        }

        &.red {
          outline: 1px solid red;
        }
      }

      &__path {
        width: 4rem;
        line-height: 20px;
        &.blue {
          color: #5057d5;
          font-weight: 800;
          font-size: 3rem;
        }

        &.black {
          color: black;
          font-weight: 400;
          font-size: 2rem;
        }
      }
    }
  }
}
