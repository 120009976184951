.ql {
  &-align {
    &-left {
      text-align: left;
    }

    &-right {
      text-align: right;
    }

    &-center {
      text-align: center;
    }

    &-justify {
      text-align: justify;
    }
  }
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8px']::before {
  content: '8px' !important;
  font-size: 8px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px' !important;
  font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px' !important;
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px' !important;
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px' !important;
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px' !important;
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px';
  font-size: 24px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
  content: '36px';
  font-size: 36px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
  content: '48px';
  font-size: 48px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='8px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='8px']::before {
  content: '8px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='14px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px' !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='36px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='36px']::before {
  content: '36px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48px']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
  content: '48px' !important;
}

.ql-color .ql-picker-options [data-value='color-picker']:before {
  content: 'Custom Color';
  color: rgb(255, 255, 255);
}

.ql-color .ql-picker-options [data-value='color-picker'] {
  background: #0068ff !important;
  width: 100% !important;
  height: 25px !important;
  text-align: center;
  color: rgb(255, 255, 255);
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    left: 0;
    top: -45px;
  }
}

// .ql-color-picker {
//   position: relative;
// }

.sum_form {
  padding: 12px;
}

.text__controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  border-top: 1px solid #eeeeee;
  padding-top: 2rem;
  &__btns {
    display: flex;
    gap: 1rem;
  }
  .ant-select-selection--single {
    height: 40px;
    border: 1.5px solid #e3e3e3;
    box-shadow: none;
  }
  .import-from-library.ant-btn-lg {
    background: #58bb68;
    color: #fff;
    border-color: #58bb68;
  }
  .ant-select.ant-select-enabled {
    max-width: 45%;
  }
}
.quill {
  .ql-toolbar.ql-snow {
    border: 1px solid #d6d6d6 !important;
    border-bottom: none;
    border-radius: 8px 8px 0px 0px;
    background: #f4f5fa;
  }
  .ql-container.ql-snow {
    border: 1px solid #e3e3e3;
  }
}

.sum__ans {
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  &_item {
    border-bottom: 1px solid #c3c3c3;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    color: #5d5d5d;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0rem;
      padding-bottom: 0rem;
    }
  }
  p {
    margin: 0rem;
    margin-bottom: 0.875rem;
    color: #000;
    font-weight: 600;
  }
}
.ant-modal.custom-ant-modal {
  max-width: 1500px !important;
}
.ant-modal.custom-ant-modal.custom-modal-audio {
  width: 60% !important;
}
