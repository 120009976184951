@import '../../../../../../styles/variables';

.create-course-menu {
  ul {
    li {
      padding: 0;
      margin-right: 20px;
      font-size: 16px;
      color: rgba(33, 33, 33, 1);
    }
    .ant-menu-item-selected {
      color: #5057d5;
      border-bottom: 2px solid #698bf2;
      .create-course-box {
        svg {
          stroke: #698bf2;
        }
      }
    }
  }
  .create-course-box {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;
    > div {
      height: 35px;
    }
    svg {
      stroke: rgba(33, 33, 33, 1);
    }
  }
}
.create-course {
  h3 {
    margin-bottom: 2rem;
  }
  .corse-market-place {
    border: 1px solid #5057d5;
    margin-left: 10px;
    border-radius: 30px;
    padding: 4px 10px;
    color: #5057d5;
    font-size: 20px;
  }
}
