.workshop-head-wrapper {
  .bdfnrR {
    gap: 20px;
  }
  .shl-left-line {
    height: 20px;
    width: 1px;
    background: #e3e3e3;
    margin: 0 3rem;
  }

  .sort-by-date-wrap {
    a {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;
      border: 1px solid #e3e3e3;
      border-radius: 16px;
      transition: 0.2s;
      cursor: pointer;
      > div {
        position: inherit;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
}
