.youtube_channeId {
  display: flex;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
  }
  &__container {
    padding: 10rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #000000;
    position: relative;
    .youtube_channeId_info {
      position: absolute;
      top: 0;
      right: 0;
      margin: 1rem;
      cursor: pointer;
    }
  }
}

.youtube_insert_button {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-transform: uppercase;
}

.channelId_image {
  width: 600px;
  img {
    width: 100%;
    object-fit: contain;
  }
}
