.workshop_marketplace_section {
  width: 100%;
  margin: 30px 0;

  display: flex;
  flex-direction: column;
  background: #fff;

  .workshop_marketplace_section_top {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .workshop_marketplace_section_left {
    width: 33%;
  }

  .workshop_marketplace_section_col {
    width: 33%;
  }

  .workshop_marketplace_section_col_right {
    width: 33%;
  }
}

.workshop_marketplace_info {
  width: 150px;
  transform: translateX(10px) translateY(20px);
  background: white;
  text-align: center;
}

.course-setting-publish {
  display: flex;
  gap: 30px;
  .course-setting-left {
    max-width: 49%;
    .ant-calendar-picker-input.ant-input {
      line-height: 1.5;
      height: 40px;
      border: 1.5px solid #e3e3e3;
      color: #212121;
    }
    .ant-calendar-picker-icon {
      color: #000000;
    }
  }
  .course-setting-right {
    max-width: 49%;
  }
}
.disclaimer-wrap {
  margin-top: 15px;
}
