.ai-quiz-container {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 2rem;
  width: 100%;
  height: 100%;
}

.quiz-generate-form {
  .ant-form-item-label {
    margin-bottom: 20px;
    text-align: center;

    label {
      font-weight: 800;
      color: #000 !important;
      margin-bottom: 20px;
    }
  }
  .question-type {
    &-container {
      display: flex;
      align-items: center;
      gap: 2rem;
      justify-content: space-evenly;
    }

    &-choice {
      width: 100px;
      aspect-ratio: 1;
      display: grid;
      place-items: center;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      outline: 2px solid #e9e9e9;
      border-radius: 50%;
      background: linear-gradient(180deg, #2e70f2 0%, #6629e8 100%);
      color: #fff;

      &:hover {
        opacity: 0.7;
      }

      &.selected {
        outline: 5px solid #22ff00;
      }
    }

    &__btn {
      button {
        background: linear-gradient(180deg, #2e70f2 0%, #6629e8 100%);
      }
      margin: 3rem auto;
    }
  }
  .question-type__btn {
    .ant-form-item-control {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .quiz-no-of-questions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    padding: 2rem;
    border: 1px solid #e8e8e8;

    &-input {
      margin-top: 3rem;
    }

    h3 {
      white-space: nowrap;
    }
  }
}

.ai-quiz-creation {
  &-main {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background: #f8f8f8;
    padding: 3rem;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;

    .ai-quiz-edit-question-container {
      flex: 0.5;
    }
  }
  &-main-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    .close-icon {
      font-size: 20px;
    }
    h1 {
      font-size: 20px;
      font-weight: 600;
      color: #000;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 1rem;

    h2 {
      font-size: 18px;
      color: #000;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 2rem;
    flex: 1;
    padding-left: 3rem;
    .ant-input-number {
      width: 100%;
      border-radius: 8px;
    }
    .quiz-setup-error {
      color: red;
      font-size: 12px;
      margin-top: 5px;
    }
    &:nth-of-type(2) {
      border-left: 1px solid #e8e8e8;
      padding-left: 3rem;
    }

    &.quiz-setup {
      outline: 1px solid #e8e8e8;
      border-radius: 20px;
      margin-bottom: 2rem;
      background: #fff;
      padding: 3rem;

      &.error {
        outline: 1px solid red;

        .ai-quiz-creation-header {
          border-bottom: 1px solid red;
          color: red;

          h2 {
            color: red;
          }
        }
      }
    }
    .ai-quiz-questions {
      &-container {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      &-action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &-btn {
          background: linear-gradient(180deg, #2e70f2 0%, #6629e8 100%);
          color: white;
        }
      }

      &-question {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        color: #000;
        min-height: 60px;
        cursor: pointer;
        padding: 0.8rem;
        margin: 1rem 0;
        border: 1.5px solid #edf2f5;
        border-radius: 8px;
        background: #fff;

        &-text {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        p {
          display: flex;
          align-items: center;
          padding: 0 2rem;
          line-height: 2rem;
          min-height: 40px;
          width: 100%;
          height: 100%;
          margin-bottom: 0 !important;
        }

        img {
          height: 15px;
        }
        &.selected p {
          outline-color: #1aba00;
          background: #fff;
        }
      }
    }
  }
}

.ai-quiz-page-selection {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    position: relative;

    &-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      font-size: 16px;
      font-weight: 600;
      color: #000;
      flex: 1;

      h1 {
        font-size: 20px;
        font-weight: 600;
        color: #000;
      }
    }

    .close-icon {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 20px;
      cursor: pointer;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;

    &-container {
      display: flex;
      gap: 4rem;
      align-items: center;
      justify-content: center;

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        cursor: pointer;

        img {
          height: 200px;
          width: 150px;
          object-fit: contain;
          border-radius: 10px;
          border: 2px solid #e9e9e9;
          padding: 2rem;
        }

        p {
          font-size: 12px;
          text-align: center;
          height: 20px;
          font-weight: 600;
          color: #000;
        }

        &:hover {
          img {
            background: #e8e8e828;
          }
        }

        &.active {
          img {
            border-color: #4f8aff;
          }
        }
      }
    }
  }

  &-action {
    &-btn {
      background: linear-gradient(180deg, #2e70f2 0%, #6629e8 100%);
      color: white;
    }
  }
}

.quiz-generate {
  width: 800px !important;
  .ant-modal-header {
    padding-top: 40px;
    .ant-modal-title {
      color: #212121;
      font-family: 'Mulish';
      font-size: 20px;
      font-weight: 500;
    }
  }
}
.ai-quiz-creation-main-sec {
  width: 100% !important;
  max-width: 80%;
}
