.video-generation-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    button {
      margin-bottom: 4rem;
    }
  }

  .scene-container {
    display: flex;
    gap: 3rem;
    margin-bottom: 3rem;
    padding: 2rem;
    background: linear-gradient(180deg, #f4f5fa 0%, #f4f5fa 100%);

    border-radius: 10px;

    .left-panel {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 300px; /* Fixed width */
      flex-shrink: 0; /* Prevent shrinking */
      border-radius: 20px;
      padding: 3rem;
      border: 2px solid #e8e8e8;
      background: #fefeff;

      .scene-settings {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }

    .right-panel {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      flex: 1; /* Take remaining space */
      width: calc(100vw - 500px - 2rem); /* Adjust dynamically */
      overflow-x: auto; /* Enable horizontal scrolling */
      overflow-y: hidden;

      .shots-grid {
        display: flex; /* Use flexbox for horizontal scrolling */
        gap: 3rem; /* Spacing between items */
        flex-wrap: nowrap; /* Prevent wrapping */
        padding: 1rem;

        .shot-card {
          flex: 0 0 400px; /* Fixed width for each item */
          border-radius: 20px;
          padding: 3rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          border: 2px solid #e8e8e8;
          background: #fff;

          .video-wrapper {
            border-radius: 5px;
            overflow: hidden;
            height: 250px; /* Ensures the wrapper always has a fixed height */
            background: #f0f2f5; /* Fallback background if no image is present */
            display: flex;
            align-items: center; /* Centers placeholder content vertically */
            justify-content: center; /* Centers placeholder content horizontally */

            &::before {
              color: #8c8c8c;
              font-size: 14px;
              display: none; /* Hidden when an image is present */
            }

            &:not(:has(img))::before {
              display: block; /* Show placeholder text if no image */
            }
          }

          .card-actions {
            display: flex;
            justify-content: space-between;

            button {
              border-radius: 20px;
              padding: 8px 16px;
            }
          }
        }
      }
    }
  }
}

.settings-drawer-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  .setting-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

    div {
      width: 100%;
    }
  }
}

.transition-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  .transition-trigger {
    cursor: pointer;
  }

  .selected-transition {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    h3 {
      color: rgba(100, 100, 100, 0.85);
      font-weight: 500;
    }
    &__tag {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 4px 8px;
      border-radius: 41px;
    }

    .anticon {
      font-size: 16px;
    }

    span {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      white-space: nowrap;
    }
  }

  .video-transition-icon {
    border: 1px solid #d9d9d9;
    padding: 6px;
    background: #fff;
    border-radius: 50%;
  }
}

.transition-selector {
  padding: 12px;

  .selector-title {
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
  }

  .transition-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }

  .transition-option {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
    text-align: left;

    &.selected {
      background: #e6f7ff;
      border-color: #1890ff;
    }

    .anticon {
      margin-right: 8px;
    }
  }
}

.video-generation-menu {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  li {
    width: 100%;
    border-radius: 4px;
    padding: 1rem;
    position: relative;
  }

  // crete a divider between items
  li:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 110%;
      height: 1px;
      background-color: #e8e8e8;
      margin: 0.5rem auto;
      bottom: -12px;
      margin-left: -20px;
    }
  }
}
