.trainer_section {
  width: 100%;
  padding: 30px;
  margin: 10px 0 30px 0;
  border: 1.5px solid #5b6ee2;
  display: flex;
  flex-direction: column;
  background-color: #fdfdfd;
  border-radius: 8px;
  // .trainer_section_top {
  //   display: flex;
  //   justify-content: space-between;
  //   flex-wrap: wrap;
  // }

  // .trainer_section_col {
  //   width: 50%;
  // }

  // .trainer_section_col_right {
  //   width: 50%;
  // }

  &_bottom {
    display: grid;
    place-items: center;
  }
  .trainer-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    > div {
      padding: 1.6rem;
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-input[disabled] {
        color: #676973;
        background-color: #edf2f5;
        cursor: not-allowed;
        opacity: 1;
        border: 1.5px solid #edf2f5;
      }
      .ant-input-group-addon {
        color: #676973;
        background-color: #edf2f5;
        border: 1.5px 0 1.5px solid #e3e3e3;
      }
    }
  }
}

.trainer_info {
  // width: 150px;
  // transform: translateX(10px) translateY(20px);
  // background: white;
  // text-align: center;
  margin: 30px 0 15px;
  font-size: 1.2rem;
}

.stripe-connect-wrapper {
  position: relative;
  margin-bottom: 30px;
  // only for master hide
  display: none;
  .stripe-connect-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    flex-wrap: wrap;
    border: 1.5px solid #edf2f5;
    border-radius: 8px;
    .stripe-connect-left {
      display: flex;
      align-items: center;
    }

    .stripe-connect-right {
      display: flex;
      align-items: center;
      gap: 30px;
      button {
        background: #5b6ee2;
        height: 40px;
        border-radius: 30px;
      }
      .ant-btn-disconnect-btn {
        background: #d9d9d9;
      }
    }
    .image {
      width: 100px;
    }
  }
}
.trainer_section_bottom {
  button {
    background: #5b6ee2 !important;
    height: 40px;
    border-radius: 30px;
  }
}
