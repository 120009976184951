@import '../../styles/variables';

.b-logo {
  display: block;
  width: 15rem;

  transition: opacity 0.2s;
  cursor: pointer;

  &_white {
    color: map-get($colors, white);
  }

  &_blue {
    color: map-get($colors, blue);
  }
  &_black {
    color: map-get($colors, black);
  }

  &:hover {
    opacity: 0.6;
  }

  &__source {
    height: 100%;
    svg {
      display: block;
    }

    img {
      max-width: 150px;
      object-fit: cover;
    }
  }
}
