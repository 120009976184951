.podcast-player {
    display: flex;
    justify-content: center;
    align-items: center;

    &_player {
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-width: 200px;

        &_body {
            background-color: #CCCCCC;
            height: 45px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            &_controls {
                width: 45px;
                padding: 6px;

                &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                }
            }

            &_waves {
                width: calc(100% - 45px);
                padding: 1.2rem 1.2rem 1.2rem 0;
                display: flex;
                justify-content: center;
                align-items: center;

                &_current {
                    padding: 0.5rem;
                    color: #000;
                }

                &_total {
                    padding: 0.5rem;
                    color: #fff;
                }

                &_background {
                    background-image: url('./wave.svg');
                    background-repeat: repeat-x;
                    background-position: center;
                    background-size: cover 30px;
                    height: 45px;
                    width: 100%;
                    position: relative;

                    .progress-body {
                        top: 2.1rem;
                        position: absolute;
                        height: 45px;
                        width: 100%;

                        .progress-bar {
                            background: #fff;
                            height: 2px;
                            cursor: pointer;
                            position: relative;
                            z-index: 100;

                            .progress {
                                background: black;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 100;
                            }
                        }
                    }

                }
            }
        }

    }

    &_downloadbtn {
        text-align: center;
        width: 70px;
        .ant-btn-link {
            padding: 0;
        }
    }
}

.podcast-audio_content_left {
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    min-height: 75px;
    padding: 0 1rem;
    min-width: 320px;
    display: flex;
    justify-content: left;
    align-items: center;

    &_player {
        background: #CCCCCC;
        height: 45px;
        min-width: 300px;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        &_controls {
            width: 45px;
            padding: 6px;

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }

        &_waves {
            width: calc(100% - 45px);
            padding: 1.2rem 1.2rem 1.2rem 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &_current {
                padding: 0.5rem;
                color: #000;
            }

            &_total {
                padding: 0.5rem;
                color: #fff;
            }

            &_background {
                background-image: url('./wave.svg');
                background-repeat: repeat-x;
                background-position: center;
                background-size: cover 30px;
                height: 45px;
                width: 100%;
                position: relative;

                .progress-body {
                    top: 2.1rem;
                    position: absolute;
                    height: 45px;
                    width: 100%;

                    .progress-bar {
                        background: #fff;
                        height: 2px;
                        cursor: pointer;
                        position: relative;
                        z-index: 100;

                        .progress {
                            background: black;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 100;
                        }
                    }
                }

            }
        }
    }
}