$sizes: (
  xxl: 1600,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576
);

$colors: (
  chatBg: #0000000d,
  default: #67686e,
  blue: #0068ff,
  purple: #4652e5,
  pageBuilderBorder: #d8d8d8,
  pageBuilderTriangle: #bbbbbb,
  usernameChat: #aaaaaa,
  textChat: #393939,
  red: #ff4b55,
  green: #00bd5d,
  orange: #ff6332,
  darkGreyOne: #777777,
  darkGreyTwo: #d8d8d8,
  darkGreyThree: #eeeeee,
  darkGreyFour: #cecece,
  white: #ffffff,
  black: #000000,
  light: #f8f8f8,
  darkBorder: #979797
);

$base: (
  fontSize: 1.4rem,
  fontFamily: 'Mulish',
  fontWeight: 400,
  fontColor: map-get($colors, darkGreyOne)
);

$typography: (
  h1FontSize: 1.8rem,
  h1FontWeight: 400,
  h2FontSize: 1.6rem,
  h2FontWeight: 700,
  h3FontSize: 1.4rem,
  h3FontWeight: 700,
  h3LineHeight: 2rem,
  h4FontSize: 1.2rem,
  h4FontWeight: 400,
  h4LineHeight: 2rem,
  labelFontSize: 1.4rem,
  labelFontWeight: 400,
  hyperLinkFontSize: 1.4rem,
  hyperFontWeight: 400,
  chatFontWeight: 600
);

// added new varible color
$color_primary: #111926; //done
$color_secondary: #f46545;
$color_white: #fff;
$color_black: #000;
$color_dark_bg: #28303b; //done
$color_light_grey: #808080;
$color_grey: #f4f3f3;
$color_light_black: #444444;
$text-color: #212121; //done
$readil-gradient: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
