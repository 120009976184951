.image-generation-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .header {
    h1 {
      color: #333;
      font-size: 18px;
      font-weight: 600;
    }

    p {
      color: #666;
      font-size: 14px;
    }
  }

  .scene-container {
    display: flex;
    gap: 3rem;
    margin-bottom: 3rem;
    padding: 2rem;
    background: linear-gradient(180deg, #f4f5fa 0%, #f4f5fa 100%);

    border-radius: 10px;

    .left-panel {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 300px; /* Fixed width */
      flex-shrink: 0; /* Prevent shrinking */
      border-radius: 20px;
      border: 2px solid #e8e8e8;
      padding: 3rem;
      background: #fefeff;

      .scene-settings {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }

    .right-panel {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      flex: 1; /* Take remaining space */
      width: calc(100vw - 500px - 2rem); /* Adjust dynamically */
      overflow-x: auto; /* Enable horizontal scrolling */
      overflow-y: hidden;

      .shots-grid {
        display: flex; /* Use flexbox for horizontal scrolling */
        gap: 4rem; /* Spacing between items */
        flex-wrap: nowrap; /* Prevent wrapping */
        padding: 1rem;

        .shot-card {
          flex: 0 0 400px; /* Fixed width for each item */
          border-radius: 20px;
          border: 1px solid #e8e8e8;
          padding: 3rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          background: #fff;

          .image-wrapper {
            border-radius: 5px;
            overflow: hidden;
            height: 250px; /* Ensures the wrapper always has a fixed height */
            background: #f0f2f5; /* Fallback background if no image is present */
            display: flex;
            align-items: center; /* Centers placeholder content vertically */
            justify-content: center; /* Centers placeholder content horizontally */
            outline: 2px solid #e8e8e8;

            img {
              height: 100%; /* Ensures the image takes up the full height of the wrapper */
              width: 100%;
              object-fit: cover;
              background: #f0f2f5;
            }

            &::before {
              content: 'No Image Available'; /* Placeholder text */
              color: #8c8c8c;
              font-size: 14px;
              display: none; /* Hidden when an image is present */
            }

            &:not(:has(img))::before {
              display: block; /* Show placeholder text if no image */
            }
          }

          .card-actions {
            display: flex;
            justify-content: space-between;
            button {
              border-radius: 20px;
              padding: 8px 16px;
            }
          }
        }
      }
    }
  }
}
