div.inactive {
  pointer-events: none;
}

div#sharewhiteboard {
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: auto;
}

div#whiteboard {
  height: 1500px;
  width: 1500px;
}

div#features {
  display: flex;
  width: 8rem; 
  height: 4rem;
  position: absolute;
  left: 45px !important;
}

div#screenshot{
  flex: 1;
  position: absolute;
  width: 40px; 
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

div#screenshot img{
  min-width: 100%;
  min-height: 100%;
}

div#clearcanvas{
  flex: 1;
  position: absolute;
  width: 40px; 
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

div#clearcanvas img{
  min-width: 100%;
  min-height: 100%;
}

div #container button{
  width: 120px;
}




