// .scenario-view {
//   &__container {
//     padding: 2rem 2rem;
//     overflow: auto;

//     h3 {
//       font-weight: 600;
//       font-size: 1.6rem;
//     }
//   }

//   &__content {
//     display: flex;
//     flex-direction: column;
//     gap: 2rem;

//     &-top {
//       display: flex;
//       justify-content: space-between;
//       gap: 1rem;

//       p {
//         font-size: 16px;
//         font-weight: 600;
//         width: 100%;
//       }

//       &-sound {
//         display: grid;
//         place-items: center;
//         position: relative;
//         cursor: pointer;
//         width: 50px;
//         height: 50px;
//         border-radius: 50%;
//         overflow: hidden;
//         outline: 1px solid #e9e9e9;

//         &:hover::before {
//           animation: none;
//         }

//         &.playing {
//           outline-color: #007bff;
//           i {
//             color: #007bff;
//             position: relative;
//             z-index: 1;
//             animation: heartbeat 1s infinite;
//           }
//         }
//       }

//       @keyframes heartbeat {
//         0% {
//           transform: scale(1);
//         }
//         50% {
//           transform: scale(1.1);
//         }
//         100% {
//           transform: scale(1);
//         }
//       }
//     }

//     &-bottom {
//       display: flex;
//       gap: 2rem;
//       justify-content: space-between;

//       &-media {
//         flex: 0.5;
//         border-radius: 2rem;
//         overflow: hidden;
//         background: #e9e9e9;
//         outline: 1px solid #e9e9e9;

//         img,
//         .react-player {
//           width: 100%;
//           height: 100% !important;
//           object-fit: cover;
//         }
//       }

//       &-options {
//         flex: 0.5;
//         display: flex;
//         flex-direction: column;
//         justify-content: flex-end;
//         gap: 2rem;
//       }

//       &-option {
//         padding: 2rem;
//         outline: 1px solid #e9e9e9;
//         border-radius: 5rem;
//         cursor: pointer;
//         display: flex;
//         align-items: center;
//         gap: 1rem;

//         &.correct {
//           outline-color: #52c41a;
//           background-color: green;
//           color: #fff;

//           &:hover {
//             background-color: #52c41a;
//           }
//         }

//         &.wrong {
//           outline-color: #f5222d;
//           background-color: red;
//           color: #fff;

//           &:hover {
//             background-color: #f5222d;
//           }
//         }

//         &:hover {
//           background-color: #e9e9e9;
//         }

//         p {
//           display: flex;
//           align-items: center;
//           height: 100%;
//           margin-bottom: 0;
//         }
//       }
//     }
//   }

//   &__rating {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: rgba(245, 245, 245, 1);
//     padding: 2rem;
//     margin-top: 1.5rem;
//     border-radius: 1rem;

//     p {
//       margin-bottom: 0;
//     }
//   }

//   &__footer {
//     display: flex;
//     gap: 2rem;
//     justify-content: space-between;
//     align-items: center;
//     margin-top: 30px;
//     margin-bottom: 20px;

//     &-attempts {
//       color: red;
//       display: flex;
//       align-items: center;
//       gap: 0.5rem;

//       i {
//         font-size: 10px;
//       }
//     }

//     &-button {
//       color: #000;
//       border-color: #000;
//       border-width: 2px;
//       text-transform: uppercase;
//       font-weight: 700;
//       width: 150px;
//     }
//   }
// }

.spin-container {
  display: grid;
  place-items: center;
  height: 100%;
}

.scenario-view {
  &__container {
    overflow: auto;
    position: relative;
    outline: 1px solid #e9e9e9;
    width: 100%;
    height: 100%;
  }

  &__items {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 1rem;
  }
  &__item {
    margin: 1rem;

    i {
      color: #fff;
    }

    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
    backdrop-filter: blur(5px);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);

    &.sound::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(35deg);
      width: 1px;
      height: 50%;
      z-index: -1;

      background: #fff;
    }

    &.playing {
      &::after {
        display: none;
      }
    }

    &.video {
      padding-top: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  &__content {
    display: flex;
    outline: 1px solid #e9e9e9;
    height: 100%;
    width: 100%;

    &-left {
      position: relative;
      padding: 2rem;
      display: flex;
      justify-content: flex-end;
      aspect-ratio: 16 / 9;
      max-width: 100%;
      overflow: hidden;
      flex: 1;

      &-media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: #000;

        img,
        .react-player {
          width: 100%;
          height: 100% !important;
          object-fit: cover;
          // show from top left corner of image
          object-position: top left;
        }
      }

      &-text {
        width: 60%;
        padding: 3rem;

        background-color: hsla(0, 0%, 0%, 0.4);
        color: #fff;
        backdrop-filter: blur(5px);
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);

        h3 {
          font-size: 20px;
          color: #fff;
          font-weight: bold;
        }

        &-story {
          margin-top: 2rem;
          font-size: 15px;
        }

        &.custom-scrollbar {
          overflow-y: auto; /* Add vertical scrolling */
          scrollbar-width: thin; /* Firefox */
          scrollbar-color: #e7e7e7 hsla(0, 0, 73, 0.27); /* Firefox */

          ::-webkit-scrollbar {
            width: 7px;
            border-radius: 5px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #f1f1f1 !important;
            border-radius: 5px;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #888 !important;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #555 !important;
          }

          ::-moz-scrollbar-button {
            width: 0px !important;
          }
        }
      }
    }

    &-right {
      padding: 4rem;
      flex: 0.5;

      &-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 2rem;
      }

      &-options {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }

      &-option {
        padding: 1.25rem 2rem;
        border-radius: 5px;
        cursor: pointer;
        background: #f5f5f5;
        color: #767676;
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0;
        }
        &:hover {
          background: #e9e9e9;
        }

        &.correct {
          background: green;
          color: #fff;
        }

        &.wrong {
          background: red;
          color: #fff;
        }
      }

      &-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;

        gap: 2rem;
      }

      &-attempts {
        margin-top: 2rem;
        color: red;
        font-size: 12px;

        i svg {
          height: 12px !important;
        }
      }
    }
  }

  &__rating {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    background: #e9e9e9;
    margin: 2rem;
  }
}
