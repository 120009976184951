.marketplace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -30px;

  .button__marketplace {
    background: #00be5e;
    font-size: 15px;
    border-radius: 50px;
    padding: 0 25px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }
}
