.event__status__dc-room {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: nowrap;
  justify-content: space-between;

  &-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    color: #246cf6;

    .__count_down__dc-room {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;
    }

    .live__status__dc-room {
      display: flex;
      flex-direction: column;
      align-items: left;
      gap: 1rem;
    }
  }

  .live__status__dc-room {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .__metadata__dc-room {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.5rem;
      color: #246cf6;

      .__started__dc-room {
        color: #37ab00;
        font-weight: bold;
      }

      .__preview__dc-room {
        max-width: 70%;

        .__title__dc-room {
          font-size: 1.8rem;
          color: #246cf6;
        }

        .__description__dc-room {
          color: #919192;

          p.collapsed {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 5px;
          }

          p.expanded {
            overflow: visible;
            margin-bottom: 5px;
          }

          .toggle-btn {
            color: #007bff;
            border: none;
            cursor: pointer;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      .__buttons__dc-room {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;

        button {
          height: 3.5rem;
          width: 12rem;
          border-radius: 2rem;
          padding: 0.5rem 1rem;
          margin: 0 0.5rem;
        }

        .__finished__button__dc-room {
          width: auto;
          border: 1px solid #ff0000;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.3rem; // Space between the icon and the text
          color: #ff0000;
          pointer-events: none;

          .__finished__button_icon_dc-room {
            color: #ff0000;
          }
        }

        .__interested__button__dc-room {
          border: 1px solid green;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.3rem; // Space between the icon and the text
          color: #37ab00;

          .__interested__button_icon_dc-room {
            color: #37ab00;

            &:hover {
              cursor: pointer;
              border-color: darken(#37ab00, 10%);
            }
          }
        }

        .__invite__button__dc-room {
          border: 1.5px solid black;

          &:hover {
            border-color: darken(black, 10%);
          }
        }

        .active {
          pointer-events: none;

        }
      }
    }

    .live__metadata__dc-room {
      padding: 1.5rem 2rem;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      color: black;
      background-color: #f0f0f0;

      .__preview__dc-room {
        max-width: 70%;

        .__title__dc-room {
          font-size: 1.5rem;
          color: #000000;
          font-weight: 600;
        }

        .__started__dc-room {
          position: absolute;
          top: -3.2rem;
          padding: 0 5px;
          background-color: #fff;
          border-radius: 5px;
          color: #C32630;
          font-weight: bold;
        }
        p.collapsed {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
        }

        p.expanded {
          overflow: visible;
          margin-bottom: 5px;
        }

        .toggle-btn {
          color: #007bff;
          border: none;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
        }
      }

      .__buttons__dc-room {
        display: flex;
        justify-content: flex-end;
        margin-left: auto;

        button {
          height: 3.5rem;
          width: 12rem;
          border-radius: 2rem;
          padding: 0.5rem 1rem;
          margin: 0 0.5rem;
        }

        .__request__button__dc-room {
          font-weight: bold;
          font-size: medium;
          // width: 10rem;
          border: 1px solid #FFA800;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.3rem; // Space between the icon and the text
          color: #FFA800;
        }

        .__leave__button__dc-room {
          font-weight: bold;
          font-size: medium;
          width: 10rem;
          border: 1px solid #ff0000;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.3rem; // Space between the icon and the text
          color: #fcfcfc;
          background-color: #ff0000;
        }

        .__end__button__dc-room {
          font-weight: bold;
          font-size: medium;
          width: 10rem;
          border: 1px solid #ff0000;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.3rem; // Space between the icon and the text
          color: #fcfcfc;
          background-color: #ff0000;
        }
      }
    }

    .__details__dc-room {
      .__date__dc-room {
        display: flex;
        justify-content: left;
        align-items: center;
        color: black;
      }

      .__users__count__dc-room {
        color: #c4c4c4;
      }
    }

    .__all_users__dc-room {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: auto;
      // margin-bottom: 10rem;
      padding-left: 5px;

      .__title__dc-room {
        display: flex;
        align-items: center;
        gap: 1rem;
        $self: &;
        display: flex;
        align-items: center;
        color: #0068FF;

        h1 {
          font-size: 18px;
          font-weight: 400;
          line-height: 16.41px;
          color: #000000;
        }

        .count {
          color: #fff;
          border-radius: 30px;
          width: 30px;
          display: grid;
          place-items: center;
          font-size: 16px;
        }

        .red {
          background: #f62424;
        }

        .grey {
          background: #c4c4c4;
        }
      }

      .__hosts__dc-room {
        display: flex;
        flex-direction: row;
        padding: 1rem 0;
        margin-bottom: 1rem;
        flex-wrap: wrap;

        .avatar-circle {
          width: 5.5rem;
          height: 5.5rem;
          justify-content: center;
          border-radius: 50%; //
          overflow: hidden; // ensures anything outside the circle gets clipped
          border: solid #f0f0f0;
          margin: 0.3rem;

          img {
            width: 100%;
            height: 100%;
          }


        }

        .progress_border {
          width: 5.5rem;
          height: 5.5rem;
          border-radius: 50%;
          float: left;
          border: 4px solid #ebc16c5e;
          border-left-color: #FAAD13;
          border-radius: 50%;
          animation: donut-spin 2s linear infinite;
        }

        .connecting_text {
          color: #FAAD13;
        }

        .avatar_image {
          animation: reverse-spin 2s linear infinite;
        }

        @keyframes reverse-spin {
          0% {
            transform: rotate(360deg);
          }

          100% {
            transform: rotate(0deg);
          }
        }

        @keyframes donut-spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        .user-card {
          position: relative;
          display: flex;
          flex-direction: column;
          text-align: center;
          margin: 5px; // space between cards
          border-radius: 1rem;
          justify-content: space-between;
          align-items: center;
          // background: rgba(239, 239, 239, 1);
          padding: 1.5rem 0.5rem;

          .handraise-button {
            position: absolute;
            bottom: 80px;
            right: 10px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            justify-content: center;
            display: flex;
            margin-top: -80px;
            align-items: center;
            // background-color: white;
            // border: white;
            // border: 1px solid rgba(239, 239, 239, 1);
            // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            // z-index: 100;

            img {
              object-fit: contain;
              height: 18px;
              width: 18px;
            }
          }

          .avatar-circle {
            width: 7rem; // adjust as needed
            height: 7rem;
            justify-content: center;
            border-radius: 50%; //
            overflow: hidden; // ensures anything outside the circle gets clipped
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

            img {
              width: 100%;
              height: 100%;
            }
          }

          .current-speaker {
            border: 2px solid #ff0000;
          }

          .microphone-button {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            justify-content: center;
            display: flex;
            align-items: center;
            background-color: white;
            border: white;
            margin-top: -20px;
            border: 1px solid rgba(239, 239, 239, 1);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            // z-index: 100;

            img {
              object-fit: contain;
              height: 18px;
              width: 18px;
            }
          }

          h3 {
            color: black;
            font-size: small;
            font-weight: 500;
            font-size: 20px;
          }
        }
      }

      .__requests__dc-room {
        display: flex;
        flex-direction: row;
        padding: 1rem 0;
        margin-bottom: 1rem;

        .avatar-circle {
          width: 5.5rem;
          height: 5.5rem;
          justify-content: center;
          border-radius: 50%; //
          overflow: hidden; // ensures anything outside the circle gets clipped
          border: solid #f0f0f0;
          margin: 0.3rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .__listeners__dc-room {
        display: flex;
        flex-direction: row;
        padding: 1rem 0;

        .avatar-circle {
          width: 5.5rem;
          height: 5.5rem;
          justify-content: center;
          border-radius: 50%; //
          overflow: hidden; // ensures anything outside the circle gets clipped
          border: solid #f0f0f0;
          margin: 0.3rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .flex__dc-room {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}