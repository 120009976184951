.share-sphere {
  &__home-subtitle {
    font-size: 16px;
    letter-spacing: 3px;
    margin-top: 10px;
    color: #777777;
  }

  .score-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 3rem;

    .sharesphere-close-button {
      background: rgb(255, 250, 250);
      color: red;
      border: 1px solid rgb(255, 166, 166);
      border-radius: 5px;
      padding: 3px 15px;
      cursor: pointer;
      gap: 10px;
      display: flex;
      align-items: center;

      span {
        font-size: 20px;
        margin-top: -5.5px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__main {
    width: 100%;
    min-height: calc(100vh - 20rem);
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 0 0 16px 16px;

    .share-sphere__finish {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #000;
      font-style: normal;
      line-height: normal;
      // text-transform: uppercase;

      .share-sphere__container {
        &_score {
          text-transform: capitalize;
          margin: 30px auto;
          height: 60px;
          border: 1px solid lightgray;
          border-radius: 30px;
          padding: 13px 25px;
          box-shadow: 0 1px 1px lightgray;

          h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            margin-top: 0;
          }
        }
      }

      div {
        img {
          height: 110px;
          min-width: auto;
        }
      }

      h4 {
        font-size: 22px;
        letter-spacing: 2px;
        font-weight: 700;
        margin-top: 7px;
      }

      h3 {
        font-size: 35px;
        margin-top: 10px;
        line-height: 75px;
        letter-spacing: 3px;
        font-weight: 700;
      }

      h1 {
        font-size: 42px;
        font-weight: 700;
        margin-top: 10px;
        letter-spacing: 5px;
      }

      h2 {
        font-size: 64px;
        font-weight: 700;
        letter-spacing: 10px;
      }

      &_btnplay {
        margin: 1rem 2rem;
        background: #ffc700;
        border-radius: 50px;
        flex-shrink: 0;
        padding: 2rem 5rem;
        box-shadow: 0 20px 20px #00000040;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
        cursor: pointer;
        border: none;
        letter-spacing: 1px;

        &:hover {
          opacity: 0.7;
          background-color: #ffb300;
        }
      }
    }

    .share-sphere {
      margin: 2rem 0;
      color: #000000;
      border-radius: 10px;
      padding: 1rem;
      background: #fff;

      .header {
        width: 100%;
        display: flex;
        align-items: flex-end;
        border-bottom: 1px solid #d9d9d9;

        .title {
          font-size: 24px;
          font-weight: 600;
          text-align: left;
          color: #0958d9;
        }

        .header_right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 2.5rem;
          padding-left: 2.5rem;
          flex: 1;
          border-left: 1px solid rgb(214, 214, 214);
        }

        .score {
          font-size: 18px;
          font-weight: 600;
          text-align: right;
          color: #6e6e6e;

          span {
            font-size: 28px;
            color: #e9a700;
          }
        }
      }

      .question {
        text-align: center;

        &_position {
          text-align: left;
          margin-bottom: 1rem;

          span {
            border-radius: 4px;
            border: 1px solid #d9d9d9;
            font-size: 12px;
            padding: 0 7px;
            line-height: 20px;
            color: #0958d9;
            background: #e6f4ff;
            border-color: #91caff;
          }
        }

        &_column_right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          min-width: 350px;
          max-width: 500px;
          gap: 1.7rem;
        }

        &_content {
          display: flex;
          gap: 4rem;
        }

        &_text {
          font-size: 24px;
          line-height: 38px;
          width: 500px;
          font-weight: 700;
          color: #434343;
          text-align: left;
        }

        &_image {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          max-height: 300px;
          min-height: 300px;
          max-width: 500px;
          transition: opacity 1s ease-in-out;

          img {
            height: 250px;
            width: 100%;
            border-radius: 20px;
            object-fit: cover;
            box-shadow: 0 2px 4px #00000040;
          }
        }

        .flip-container {
          perspective: 1000px;
          height: 250px;
          width: 500px;
        }
        .flip-container.flip .flipper {
          transform: rotateY(180deg);
        }

        .flip .flipper {
          transition: 0.6s ease-in;
        }

        .flipper {
          transform-style: preserve-3d;
          position: relative;
          border-radius: 20px;
        }
        .front,
        .back {
          backface-visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          height: 250px;
          width: 500px;
          background-color: #f4f5fa;
          border-radius: 20px;
        }
        .front {
          z-index: 2;
          transform: rotateY(0deg);
        }
        .back {
          transform: rotateY(180deg);
        }

        .front img,
        .back img {
          height: 250px;
          width: 500px;
          border-radius: 20px;
          object-fit: cover;
        }

        &_answer {
          width: 100%;
          border: 1px solid lightgray;
          padding: 1rem 2rem;
          border-radius: 50px;
          text-align: left;
          gap: 1rem;
          position: relative;
          display: flex;
          align-items: center;
          min-height: 50px;

          &:hover {
            cursor: pointer;
            box-shadow: 0 0 10px #e4e4e4;
          }
        }

        &_correct {
          color: #fff;
          background-color: #2a812b;
          i {
            font-size: 18px;
          }
        }

        &_incorrect {
          color: #fff;
          background-color: #d10c09;
          i {
            font-size: 18px;
          }
        }

        &_next {
          text-align: center;
          width: 100%;

          &_btn {
            background: #245fb1 !important;

            width: 100%;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1050px) and (max-width: 1280px) {
    .main {
      padding-top: 32px;
      height: 100vh;

      .trivia {
        margin: 0 4rem;

        .header {
          padding-top: 0;
          padding-bottom: 0;
        }

        .question {
          padding: 0;

          &_answer {
            max-height: 500px;
            max-width: 500px;
            min-width: 350px;
          }

          &_correct {
            max-height: 500px;
            max-width: 500px;
            min-width: 350px;
          }

          &_incorrect {
            max-height: 500px;
            max-width: 500px;
            min-width: 350px;
          }

          &_next {
            &_btn {
              width: 70%;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 780px) and (max-width: 1050px) {
    .main {
      padding-top: 32px;
      height: 100vh;

      .trivia {
        margin: 0 3.5rem;

        .header {
          padding-top: 0;
          padding-bottom: 0;
        }

        .question {
          padding: 0;

          &_answer {
            max-height: 500px;
            max-width: 500px;
            min-width: 300px;
          }

          &_correct {
            max-height: 500px;
            max-width: 500px;
            min-width: 300px;
          }

          &_incorrect {
            max-height: 500px;
            max-width: 500px;
            min-width: 300px;
          }

          &_next {
            &_btn {
              width: 70%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 780px) {
    .main {
      padding-top: 32px;

      .finish {
        .container {
          padding: 10px;

          &_score {
            text-transform: capitalize;
            margin: 20px auto;
            width: 300px;
            height: 50px;
            border: 1px solid lightgray;
            border-radius: 25px;
            padding: 10px 20px;
            box-shadow: 0 1px 1px lightgray;

            h3 {
              font-size: 22px;
              font-weight: 700;
              line-height: 26px;
              margin-top: 0;
            }
          }
        }

        h4 {
          font-size: 16px;
          letter-spacing: 5.4px;
          font-weight: 700;
          margin-top: 5px;
        }

        h3 {
          font-size: 28px;
          font-weight: 700;
          margin-top: 10px;
          line-height: 32px;
          letter-spacing: 2px;
        }

        h1 {
          font-size: 48px;
          font-weight: 700;
          margin-top: 10px;
          letter-spacing: 5px;
        }

        h2 {
          font-size: 32px;
          font-weight: 700;
          margin-top: 10px;
          letter-spacing: 2px;
        }
      }

      .trivia {
        margin: 0 1.5rem;

        .header {
          padding-top: 0;
          padding-bottom: 0;
        }

        .question {
          padding: 0;

          &_answer {
            max-height: 500px;
            max-width: 500px;
            min-width: auto;
          }

          &_correct {
            max-height: 500px;
            max-width: 500px;
            min-width: auto;
          }

          &_incorrect {
            max-height: 500px;
            max-width: 500px;
            min-width: auto;
          }

          &_next {
            &_btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
