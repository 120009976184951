.maze_create {
  &__template {
    margin-bottom: 4rem;
    font-weight: 600;
    position: relative;
    border: 3px dashed transparent;
    border-radius: 10px;
    outline: none;
    padding: 1px;
    width: 97%;
  }

  &__progress {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    width: 50%;

    p {
      white-space: nowrap;
    }
  }

  &__deadend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    span {
      &:last-child {
        margin-left: auto;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .ant-select-dropdown {
    width: auto !important;
  }
  &__top {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    .select_box {
      height: 3rem;
      width: 5rem;
      border-radius: 5px;
      border: 1px solid #c4c4c4;
      display: grid;
      place-items: center;
      overflow: hidden;
      cursor: pointer;
      position: relative;

      &:hover {
        border-color: #136cfb;
      }

      &.black {
        background-color: black;
      }

      img {
        height: 2.5rem;
        object-fit: contain;
      }
    }
  }

  &__bottom {
    position: relative;
    textarea {
      width: 100%;
      resize: none;
      border-color: #c4c4c4;
    }

    &_text {
      color: rgb(179, 179, 179);
      position: absolute;
      font-size: 12px;
      right: 10px;
      bottom: -5px;
    }

    .red__text {
      color: red;
    }
  }

  &__delete {
    position: absolute;
    bottom: 25%;
    right: -2.75rem;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 1rem;
    margin-bottom: 5px;
    cursor: pointer;
  }
}

.avatar-img {
  height: 50px;
  width: 20px;
  object-fit: contain;
  margin-right: 1rem;
}

.maze-score {
  position: relative;

  .error_message {
    position: absolute;
    top: 35px;
    width: 100px;
  }
}

.template-select {
  transform: translateY(12px);
}

.red_border {
  border: 1px solid red;
}

.maze-template-blink {
  animation: border-blink 1s steps(1) 2;
}

@keyframes border-blink {
  0% {
    border-color: #136cfb;
  }

  50% {
    border-color: transparent;
  }
}
