.toolbar {
  background: linear-gradient(106.91deg, #f8f4e8 1.04%, #e9eef5 44.36%);
  position: relative;
  padding: 3rem 0;
  &__autobg {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 6%;
    top: 50%;
    transform: translateY(-50%);
    p {
      margin: 0;
      font-size: 1.1rem;
      color: #333;
      font-weight: 600;
      margin-bottom: 0.2rem;
    }
  }

  &__inner {
    display: flex;
    justify-content: center;
    background: #fff;
    width: max-content;
    margin: auto;
    padding: 1rem 2rem;
    border-radius: 30px;
    box-shadow: 0px 0px 20px 0px #e4eaee;
  }

  &__list {
    display: flex;
    padding: 0 1rem;
    margin: 0 -1rem;
    list-style: none;
    gap: 2rem;
    @media (max-width: 768px) {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
    }
    > span {
      display: flex;
      gap: 10px;
      .toolbar__item-icon {
        font-size: 2rem;
      }
    }
    .toolbar__item {
      all: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;

      &:hover {
        .toolbar__item-icon {
          background: #5057d52c;
        }

        .toolbar__item-text {
          color: #5057d5;
        }
      }

      &.active_grid .toolbar__item-icon {
        position: relative;
        display: grid;
        place-items: center;
        outline: 1px solid #5057d5a4;
        background: #5057d52c;

        &::before {
          /* Checkmark symbol */
          content: '\2713';
          position: absolute;
          top: 0;
          right: -10px;
          font-size: 10px; /* Adjust size as needed */
          background: #377e22;
          color: #dcffe4; /* Checkmark color, change as needed */
          z-index: 1; /* Ensure checkmark is above the overlay */
          height: 20px;
          width: 20px;
          border-radius: 100%;
          display: grid;
          place-items: center;
        }
      }

      &-icon {
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: linear-gradient(106.91deg, #f8f4e8 1.04%, #e9eef5 44.36%);
        font-size: 2rem;
      }

      &-text {
        display: block;
        font-size: 1.1rem;
        color: #707070;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.4rem 1rem;
    width: 4rem;
    height: 4rem;
    border: 1.5px solid transparent;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background: rgba(#fff, 0.4);
      border-color: #0068ff;
      border-radius: 100%;
    }

    &:active {
      background: rgba(#fff, 0.6);
    }

    &-icon {
      font-size: 2.4rem;
      line-height: 1 !important;
    }

    &-text {
      font-size: 1.1rem;
      color: #333;
      display: none;
    }
  }

  @media (max-width: 1250px) {
    &__autobg {
      right: 5%;
    }
  }
}

.background-preview {
  height: 100%;
  aspect-ratio: 1/1;

  .color-preview,
  .image-preview {
    width: 100%;
    height: 100%;
    background-size: cover;
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

.background-indicator {
  display: flex;
  align-items: center;
  gap: 8px;

  position: relative;
  .current-background {
    cursor: pointer;
    margin-top: 6px;
    border: 2px solid #999;
    outline: 2px solid #fff;
    border-radius: 50%;

    &:hover {
      opacity: 0.8;
    }
  }

  .remove-background {
    color: #999;
    cursor: pointer;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      color: #666;
    }
  }
}

.background-image-preview {
  img {
    box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .opacity-control {
    margin: 2rem 0;
  }
}

.background-options {
  .background-option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    span {
      margin-left: 8px;
      text-transform: capitalize;
    }
  }
}

.background-actions {
  display: flex;
  gap: 12px;
  margin-top: 12px;
  justify-content: center;
}

.color-picker-wrapper {
  background-color: #f4f5fa;
  border-radius: 20px;
  .chrome-picker {
    width: 100% !important;
    border-radius: 30px;
    box-shadow: 0 0 10px 0 #e4eaee;
    margin-bottom: 30px;
  }
}
