.youtube_videos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
  padding: 2rem;
  background: #ebebeb;

  &_actions {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .youtube_video {
    position: relative;
    box-shadow: 0 0 3px 2px #d6d6d6;

    .ant-card-meta {
      margin-bottom: 28px;
      height: 100px;
    }

    .select-youtube-video {
      position: absolute;
      top: calc(1rem + 9px);
      left: 1rem;
      transform: scale(1.5);
      margin-left: 8px;

      span.ant-checkbox-inner {
        background-color: #ffffff;
        border-color: rgb(148, 148, 148);
      }

      &-aiLang {
        margin-top: 1rem;
      }

      .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: blue;
      }
    }

    cursor: pointer;

    &:hover {
      box-shadow: 0 0 3px 2px #7e7e7e;
    }
  }

  .youtube_video_thumbnail {
    object-fit: cover;
    height: 180px;
    width: 100%;
    overflow: hidden;
  }

  &_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 0;
    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  &_select_all {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 500;
    color: #000000;

    label {
      margin-top: 3px;
    }
    &:hover {
      color: #000000;
      text-decoration: underline;
    }
  }
}
