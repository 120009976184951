.file_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .file_item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .file_title {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 19px;
    }
    .download_link {
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.no_files {
  font-size: 17px;
  color: #000000cf;
  font-weight: 600;
}
