.ai-course-form {
  padding: 3rem;
  padding-bottom: 0;

  .ant-form-item-label {
    label {
      text-transform: none;
      color: #212121 !important;
      font-size: 15px;
      font-weight: 600;
    }
  }

  .ant-radio-wrapper span {
    color: #000;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      //   background: #1aba00;
      //   color: #fff;
      padding: 0 40px !important;
    }
  }

  &__duration {
    // display: flex;
    // align-items: center;
    // gap: 5px;
    .ant-input-number {
      height: 40px;
      margin-top: 10px;
      border-radius: 8px;
      overflow: hidden;
    }

    .ant-form-item-label {
      padding: 0 !important;
    }

    .ant-form-item-children {
      > span {
        color: #000;
      }
      .ant-input-number {
        margin-right: 5px;
      }
    }
  }
  input {
    height: 40px;
    border: 1.2px solid #e3e3e3;
  }
}
