.certificate {
  &-loading {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    font: bold;
  }

  &-template {
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1.5px solid #5b6ee2;
    border-radius: 16px;
    input {
      width: 100%;
      border: none;
      text-align: center;
      margin: 1px 0;
      color: black;
      background: transparent;
      &:focus {
        outline: 1px dotted black;
      }
    }
  }
  &-text {
    position: relative;
    textarea {
      border: none;
      margin: 1px 0;
      text-align: center;
      resize: none;
      width: 350px;
      height: 60px;
      line-height: 13px;
      color: black;
      background: transparent;
      &:focus {
        outline: 1px dotted black;
      }
    }

    textarea:focus ~ .certificate-text-length {
      display: flex;
    }

    &-length {
      position: absolute;
      top: -2rem;
      right: 0;
      margin: 5px;
      font-size: 11px;
      color: rgba(0, 0, 0, 0.307);
      display: none;
    }
  }
  &-logo {
    margin-top: 20px;
    img {
      object-fit: contain;
    }
  }
  &-title {
    width: 350px;
    font-size: 14px;
  }

  &-midtext {
    width: 70%;
    font-size: 12px;
  }

  &-username {
    font-size: 20px;
    input {
      font-style: italic;
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-around;
    margin-top: -0.75rem;

    .icon {
      margin-top: 1rem;
      width: 60px;
      height: 60px;
    }
    .signature {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &-img {
        margin-bottom: -15px;
      }
      span {
        margin: 0 auto;
      }
    }
  }

  .certificate-logo,
  .signature-img,
  .icon {
    position: relative;
  }

  .certificate-logo:hover &_clear_image,
  .signature-img:hover &_clear_image,
  .icon:hover &_clear_image {
    display: grid;
  }

  &_clear_image {
    position: absolute;
    height: 50px;
    width: 50px;
    transform: translateY(-50px);
    background-color: rgba(152, 141, 250, 0.692);
    display: none;
    place-items: center;
    color: ivory;
    cursor: pointer;
    img {
      height: 60%;
      width: 60%;
    }
  }
}
