.media-manager {
  $self: &;

  &__inner {
  }

  &__list {
    #{$self}__item {
      padding: 0.2rem 2.2rem 0.2rem 0.2rem;
    }

    #{$self}-item {
      position: relative;
      padding: 1rem;
      font-weight: 600;
      transition: color 0.2s;
      cursor: pointer;
      border-radius: 0.4rem;

      box-shadow: 0 0 0 0.2rem transparent;
      transition: box-shadow 0.2s;

      &:hover {
        color: #0068ff;
        box-shadow: 0 0 0 0.2rem #0068ff;
      }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    padding: 0 1rem 0 0;

    #{$self}__item {
      width: 33.333332%;
      padding: 0.4rem 1.4rem;
      margin-bottom: 2rem;
    }

    #{$self}-item {
      border-radius: 0.4rem;

      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      box-shadow: 0 0 0 0.4rem transparent;
      transition: box-shadow 0.2s;

      cursor: pointer;

      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      &:hover {
        box-shadow: 0 0 0 0.4rem #0068ff;
      }
    }
  }

  &__controls {
    padding-top: 2rem;
  }
}
