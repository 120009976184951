.quiz-unity {
  &-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    color: black;
    background: #f4f5fa;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .unity {
    width: 100%;
    height: 100%;
  }
}
