.coded {
  ul {
    li {
      label {
        justify-content: center;
        align-items: center;
        flex: 1;
        display: flex;
      }
    }
  }
}
