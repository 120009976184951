.breakout {
  border: 1px solid #cccccc;
  margin-top: 3rem;

  &__top {
    p {
      margin-top: 5px;
      font-size: 10px;
      line-height: 13px;
      text-transform: capitalize;
      color: #8e8e8e;
    }
  }

  &__head {
    padding: 1.2rem;
    border-bottom: 1px solid #cccccc;

    font-size: 1.6rem;
    font-weight: bold;
  }

  &__delete {
    padding: 0 1rem;
    cursor: pointer;
    float: right;
    &:hover {
      color: #0068ff;
    }
  }

  &__disabled {
    padding: 4rem 2.4rem;
    text-align: center;
    background: #efefef;
  }

  &__content {
    display: grid;
    grid-template-columns: 0.6fr 0.4fr;
    align-content: end;
    gap: 1rem;
    padding: 1.2rem;
    padding-bottom: 0;
  }

  &__room {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__buttons {
      padding: 1rem;
    }
  }

  // &__delete {
  //   float: right;
  //   cursor: pointer;
  // }

  &__noaction {
    pointer-events: none;
  }

  &-answers {
    &__row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &__input {
      flex: 1;
    }

    &__vote {
      cursor: pointer;
    }

    &__voted {
      pointer-events: none;
      cursor: none;
    }

    &__item {
      position: relative;
      padding: 0.8rem 1.2rem;
      border-radius: 5rem;
      border: 1px solid #efefef;
      margin-bottom: 0.8rem;
      overflow: hidden;
      cursor: pointer;

      &-voted {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 3;
      }

      &-text {
        position: relative;
        font-size: 1.4rem;
        font-weight: 300;
        font-style: italic;
        z-index: 2;
      }

      &-progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #efefef;
        z-index: 1;

        transition: width 0.2s;
      }
    }
  }
}
