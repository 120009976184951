.ai-videos-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  margin-top: 3rem;

  .ai-videos-form {
    padding: 3rem 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    [name='prompt'] {
      width: 60%;
    }

    &-label {
      span {
        text-align: center;
        color: #000;
        text-transform: none;
        font-size: 1.6rem;
        padding-left: 0.6rem;
      }

      &-icon {
        color: #126DFB !important;
        font-size: 1.8rem !important;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }

    }

    h1 {
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase;
      color: #136cfb;
    }

    .ant-btn {
      &.ant-btn-primary {
        background: linear-gradient(to right, #136cfb, #0a4b8e);
        border: none;

        &:hover {
          background: linear-gradient(to right, #0a4b8e, #136cfb);
        }
      }
    }
  }

  .ai-images {
    width: 50%;
    min-width: 350px;
    margin: 0 auto;
    overflow-y: auto;
    min-height: 100px;
    max-height: 300px;
    box-shadow: 0 1px 2px 0 rgba(68, 68, 68, 0.055);
    border-radius: 1rem;

    &-action {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-list {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      padding: 1.2rem 0;

      &-cover {
        min-height: 100px;
        max-height: 150px;
        border: 0.1rem solid #e9e9e9;
        background: #fff;
        display: flex;
        padding: 1.3rem;
        margin-top: -0.1rem;
        margin-right: 1rem;
        cursor: grab;
      }
    }
  }


  .ai-videos-result {
    padding: 2rem 4rem 4rem 4rem;
    margin: 0 auto;
    border-top: 1px solid #e9e9e9;
  }

  .ant-form-item-label {
    font-weight: bold;
  }

  .video-preview {
    text-align: center;
    padding-top: 2rem;

    &-actions {
      padding-top: 1rem;

      .ant-select {
        max-width: 300px;
      }

      &-buttons {
        display: flex;
        gap: 2rem;
        width: 100%;
        justify-content: center;
      }
    }
  }

  .ant-progress {
    width: 80%;
    border-radius: 10px;
    background-color: rgba(19, 108, 251, 0.1);

    .ant-progress-inner {
      border-radius: 10px;
      background: linear-gradient(to right, #136cfb, #0a4b8e);
    }
  }

  .ant-select {
    width: 100%;
  }

  .ant-select-selection {
    border-color: #136cfb;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .ant-btn {
    font-weight: bold;
    border-radius: 5px;
  }
}