.video-generation__step-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
  position: relative;
  padding-bottom: 2rem;
  flex: 1;

  // display a divider at  the bottom
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #f3f3f3;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    padding: 10px;
    border-radius: 50%;

    i {
      font-size: 24px;
      color: #333;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      color: #333;
      font-size: 18px;
      font-weight: 600;
    }

    p {
      color: #666;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
