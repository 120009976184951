.discussion-machine-main {
    position: relative;
    height: calc(100vh - 32rem);
    z-index: 100;

    &-body {
        height: calc(100vh - 35rem);
        overflow-y: auto;
        padding: 1.2rem;

        .ck-editor__editable_inline {
            z-index: 101;
            min-height: 150px !important;
            max-height: 250px !important;
            overflow: auto;
        }

        &-action {
            border: 1px solid #E3E3E3;
            padding: 1.2rem;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .generate-btn {
                width: 175px;
                padding: 1.2rem;
            }

            .generate-audio {
                width: calc(100% - 175px);
                padding: 0 1.2rem;
            }
        }
    }

    &-footer {
        height: 10rem;
        display: flex;
        width: 100%;
        border-top: 1px solid #E3E3E3;
        justify-content: right;
        align-items: center;
        position: absolute;
        bottom: -10rem;

        &-inner {
            padding: 0 4rem;
        }
    }
}

.dropzone-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.4rem;

    .music-dropzone {
        width: 100%;
        height: 100%;
        text-align: center;
        transition: border-color 0.2s, background 0.2s;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-content: center;



        .music-dropzone__text-wrapper {
            display: block;
            padding: 50px;

            img {
                margin-bottom: 40px;
                width: 40px;
            }
        }
    }
}