.error_message {
  color: #ff4b55;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-modal-content {
  border-radius: 1.2rem;
}

.ant-modal-header {
  border-radius: 1.2rem;
}

.ant-modal-footer {
  border-radius: 1rem;
}

.ant-modal-title {
  color: #212121;
  font-weight: bold;
}

.wrap_upload_buttons {
  display: flex;
  place-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  cursor: pointer;
  button {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    font-size: 14px;
    max-width: 100%;
    margin: auto;
    // max-width: 48%;
    // flex: 0 0 48%;
    > div {
      border-radius: 100%;
      width: 24px;
      height: 24px;
    }
    @media (max-width: 1300px) {
      font-size: 12px;
    }
  }
}
