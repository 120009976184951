.ai_image_container {
  height: 20rem;
  width: 80%;
  overflow: hidden;
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: grab;

    &:hover {
      border: 2px solid rgb(81, 149, 228);
    }
  }
}

.ai-image-slider {
  margin: 0 2rem;
}

.ai-preview-modal {
  height: 80vh;
  width: 80vw !important;
  .ai-modal-image {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.ai-image-preview {
  h2 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  img {
    height: 30rem;
    max-width: 100%;
    object-fit: contain;
    cursor: pointer;

    &:hover {
      border: 2px solid rgb(81, 149, 228);
    }
  }
}

.slide_image_container {
  width: 80vw;
  height: 80vh;
  background: rgb(201, 200, 200);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }
}