.voiceover-generator {
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      display: flex;
      flex-direction: column;
      h1 {
        color: #333;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        color: #666;
        font-size: 14px;
      }
    }

    button {
      margin-bottom: 24px;
      i {
        margin-right: 8px;
      }
    }
  }

  .text-input {
    margin-bottom: 24px;
  }

  .selection-hint {
    text-align: center;
    padding: 16px;
    background-color: #f0f2f5;
    border-radius: 4px;
    margin-bottom: 16px;
    color: #666;

    i {
      margin-right: 8px;
      color: #1890ff;
    }
  }

  .voice-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

  .audio-player {
    margin-top: 24px;
    text-align: center;

    audio {
      width: 100%;
      max-width: 500px;
    }
  }

  .ant-result {
    padding-bottom: 0;
  }

  .voiceover-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
  }
}

.voice-settings-modal {
  .ant-modal-header {
    border-bottom: none;
    padding: 24px 24px 0;
  }

  .ant-modal-body {
    padding: 8px 24px;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    border-top: none;

    .generate-btn {
      background-color: #8f8f8f;
      border-color: #8f8f8f;

      &:hover {
        background-color: darken(#8f8f8f, 10%);
        border-color: darken(#8f8f8f, 10%);
      }
    }
  }

  .settings-content {
    .model-section {
      margin-bottom: 24px;

      h4 {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .model-option {
        .model-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;

          .model-name {
            font-weight: 500;
          }

          .recommended {
            font-size: 12px;
            color: #8c8c8c;
          }
        }

        .model-description {
          font-size: 14px;
          color: #8c8c8c;
          margin-bottom: 8px;
        }

        .model-languages {
          .ant-tag {
            margin: 4px;
            background-color: #f5f5f5;
            border: none;
            font-size: 12px;
          }
        }
      }
    }

    .slider-section {
      margin-bottom: 24px;

      .slider-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        font-size: 14px;

        .slider-label {
          font-size: 12px;
          color: #8c8c8c;
        }
      }

      .slider-label-left {
        font-size: 12px;
        color: #8c8c8c;
        margin-top: 4px;
      }

      .ant-slider {
        margin: 8px 0;

        .ant-slider-track {
          background-color: #000000;
        }

        .ant-slider-handle {
          border-color: #000000;

          &:focus {
            border-color: #000000;
          }
        }
      }
    }

    .switch-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      font-size: 14px;

      .ant-switch {
        &.ant-switch-checked {
          background-color: #000000;
        }
      }
    }
  }
  // Override antd select styles when focused
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: #000000;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  }

  // Override antd select dropdown styles
  .ant-select-dropdown-menu-item {
    &:hover,
    &-active,
    &-selected {
      background-color: #f5f5f5;
    }
  }
}

.voice-selector-table {
  .clickable-row {
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }

  .ant-table-body {
    margin: 0 !important;
    .ant-table-thead .ant-table-row-cell-break-word {
      padding: 2rem !important;
    }
  }

  .selected-row {
    background-color: #e6f7ff;
  }

  .voice-name {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    color: #5057d5;

    .voice-title {
      font-weight: bold;
      font-size: 16px;
    }

    .voice-gender {
      font-size: 12px;
      margin-left: 4px;
      border-radius: 4px;
      color: #333;
      padding: 2px 4px;
      text-transform: capitalize;
    }
  }

  .voice-labels {
    display: flex;
    flex-wrap: wrap;

    .ant-tag {
      margin-right: 4px;
      margin-bottom: 4px;
      background-color: #f5f5f5;
      border: none;
      font-size: 12px;
      text-transform: capitalize;
      border-radius: 4px;
    }
  }

  .ant-btn {
    padding: 0 2rem;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    .anticon {
      margin-right: 4px;
    }
  }

  .preview-button {
    display: flex;
    align-items: center;

    .ant-btn {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}
