.roles-management {
  padding: 3rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 0 1.5rem;

    .title {
      h1 {
        font-size: 16px;
        font-weight: 600;
        color: #0068ff;
      }

      p {
        font-size: 12px;
      }
    }
  }
  tbody {
    tr {
      td {
        color: rgba(0, 0, 0, 0.8);
        font-weight: 600;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 2rem;
    justify-content: flex-end;
  }

  &__users-list {
    margin-bottom: 50px;
    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
.permission-checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}

.role-management-menu {
  li {
    padding: 0;
    font-size: 16px;
    color: #5057d5;
    border-bottom: 1px solid #e5e5e5;
    &:last-child {
      border-bottom: none;
    }
  }
  i {
    font-size: 16px !important;
  }
  .ant-menu-item-selected {
    color: #5057d5;
    border-bottom: 2px solid #698bf2;
  }
}

.permission-drawer,
.user-drawer {
  &-footer {
    button {
      background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%) !important;
    }
  }
}

.permission-drawer,
.user-drawer {
  .ant-drawer-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0;
    text-transform: none;
    color: #5057d5;
  }
}

.users-management__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.user-drawer__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
