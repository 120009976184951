@import '../../../styles/variables';

.podcast-record {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  &-icon {
    margin: 10px;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 18px;
    height: 18px;
    border: 6px solid transparent;
    box-shadow: 0 0 0 4px, inset 0 0 0 10px;
    border-radius: 100px;
    cursor: pointer;
  }

  &-enabled {
    color: red;
    opacity: 1;
  }

  &-disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
  }
}

.podcast-ai {
  // position: absolute;
  // top: 3.2rem;
  // right: 11rem;
  // padding-right: 3.2rem;
  display: flex;
  justify-content: left;
  align-items: center;

  &-title {
    font-size: 1.2rem;
    color: #246cf6;
    line-height: 18px;
    padding: 0 5px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &-checkbox {
    padding-right: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 600;

    &-title {
      font-size: 1.2rem;
      line-height: 18px;
      padding: 0 5px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &-icon {
      margin: 10px;
      box-sizing: border-box;
      position: relative;
      display: block;
      transform: scale(var(--ggs, 1));
      width: 18px;
      height: 18px;
      border: 6px solid transparent;
      box-shadow: 0 0 0 4px, inset 0 0 0 10px;
      border-radius: 100px;
      cursor: pointer;
    }

    &-enabled {
      color: #399dd7;
      opacity: 1;
    }
  }
}

.shl-podcast-head {
  display: flex;
  margin: 0 1rem;
}