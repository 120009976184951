.upload-file-main {
    height: 400px;
    overflow-y: auto;
    padding: 2.4rem;
    border-radius: 16px;
    border: 1.5px solid #E3E3E3;

    &-action {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border: 2px solid #E3E3E3;
        border-style: dotted;
        padding: 1.2rem 1.6rem;
        border-radius: 8px;

        &-right {
            display: flex;
            gap: 1.2rem;

            &-icon {
                display: flex;
                align-items: center;
            }

            &-content {
                align-items: left;

                &-title {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.4px;
                    text-align: left;
                    color: #212121;
                }

                &-size {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.4px;
                    text-align: left;
                }
            }
        }


        &-btn {
            height: 40px;
            width: 40px;
            background-color: #EDF2F5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }
    }

    &-filediv {
        margin-top: 0.6rem;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        border: 2px solid #E3E3E3;
        padding: 1.2rem 1.6rem;
        border-radius: 8px;

        &-right {
            display: flex;
            gap: 1.2rem;
            width: calc(100% - 60px);

            &-icon {
                display: flex;
                align-items: center;

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            &-content {
                display: flex;
                text-align: left;
                align-items: center;

                &-title {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.4px;
                    text-align: left;
                    color: #212121;
                    word-break: break-word;
                    overflow-wrap: break-word;
                }

                &-size {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.4px;
                    text-align: left;
                }
            }
        }


        &-btn {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                margin: 0 0.6rem;

                &:hover {
                    cursor: pointer;
                    opacity: 0.7;
                }
            }
        }
    }
}