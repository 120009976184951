.maze_right {
  border: 1px solid #d7d4d4;
  border-radius: 20px;
  padding: 3rem;
  margin-top: 2rem;

  .images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;

    label img {
      height: 20rem;
      max-width: 15rem;
      margin-bottom: 1rem;
    }
  }

  .preview_button button {
    padding: 0.5rem 1.5rem;
    border: 1px solid #136cfb;
    color: #136cfb;
    border-radius: 3rem;
    background: white;
    outline: none;
    cursor: pointer;
    margin-bottom: 1rem;

    &:hover {
      color: #78a8f7;
      border-color: #78a8f7;
    }
  }

  &_title {
    font-weight: bold;
  }

  &_top {
    input {
      margin-top: 1rem;
    }
  }

  &_middle {
    img {
      height: 10rem !important;
      object-fit: cover;
    }
  }

  &_bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .maze_action {
      height: 15rem;
      border: 1px solid #d7d4d4;
      border-radius: 10px;
      background-size: cover;
      span {
        margin-top: 1rem;
        color: #136cfb;
        border-radius: 3px;
        line-height: 24px;
        width: 15rem;
        display: flex;
        justify-content: center;
      }

      &:hover {
        border-color: #136cfb;
      }
    }
  }

  label,
  .maze_action {
    cursor: pointer;
  }

  .column,
  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  hr {
    margin-bottom: 3rem;
    background-color: #dddddd;
    height: 1px;
    border: 0;
  }
}

.div_disabled {
  background: rgb(228, 228, 228);
  cursor: not-allowed !important;
}
