.add-new-template {
    width: 1000px !important;

    .template-body {
        &-title {
            font-size: 14px;
            color: #212121;
            text-transform: capitalize;
            padding-bottom: 2rem;
            font-weight: 500;
        }

        &-layout {
            max-height: 553px !important;
            overflow-y: auto;
            border-width: 1.5px;
            border-color: #e3e3e3;
            border-style: solid;
            border-radius: 8px;

            .ant-card-body {
                padding:0.6rem;
            }

            &-card {
                min-height: 200px;
                margin: 1rem;
                border-radius: 8px;

                &-title {
                    font-size: 14px;
                    line-height: 18px;
                    color: #212121;
                    font-weight: 500;
                    padding-bottom: 1.2rem;
                }

                &-body {
                    width: 100%;
                }
            }
        }
    }

    // .ant-modal-body {
    //     padding:1.2rem 2rem;
    // }
}