.poll {
  border: 1px solid #efefef;

  &__head {
    padding: 1.2rem;
    border-bottom: 1px solid #efefef;

    font-size: 1.6rem;
    font-weight: 600;
  }

  &__disabled {
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    background: #efefef;
  }

  &__content {
    padding: 1.2rem;
  }

  &__question {
    font-weight: 700;
    padding-bottom: 1.2rem;
    clear: both;
  }

  &__ask__question {
    float: right;
    cursor: pointer;
  }

  &__asked {
    color: #0068ff;
    pointer-events: none;
    cursor: none;
  }

  &__delete {
    float: right;
    cursor: pointer;
  }

  &__noaction {
    pointer-events: none;
  }

  &-answers {
    &__row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &__input {
      flex: 1;
    }

    &__delete {
      padding: 0 1rem;
      cursor: pointer;

      &:hover {
        color: #0068ff;
      }
    }

    &__vote {
      cursor: pointer;
    }

    &__voted {
      pointer-events: none;
      cursor: none;
    }

    &__item {
      position: relative;
      padding: 0.8rem 1.2rem;
      border-radius: 5rem;
      border: 1px solid #efefef;
      margin-bottom: 0.8rem;
      overflow: hidden;
      cursor: pointer;

      &-voted {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 3;
      }

      &-text {
        position: relative;
        font-size: 1.4rem;
        font-weight: 600;
        font-style: italic;
        z-index: 2;
      }

      &-progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: #749dff;
        z-index: 1;

        transition: width 0.2s;
      }
    }
  }
}
