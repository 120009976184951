.podcast-list {
    margin-top: 23.5px;

    border: 1px solid #C7C7C7;
    border-radius: 7px 7px 0 0;
    width: 100%;
    // temporary fix
    // border-radius: 7px 7px 0 0;
    // width: 29%;
    // position: fixed;

    @media (max-width: 768px) {
        position: relative;
        width: 100%;
    }

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 2rem;
        border-bottom: 1px solid #C7C7C7;
        min-height: 42px;

        &_title {
            color: #000000;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            padding-right: 1rem;
        }

        &_btn {
            display: flex;
            .btn-size {
                padding: 0.5rem 1rem;
                font-size: 1.2rem;
                height: auto;
                margin-right: 0.5rem;

                .ant-btn[disabled] {
                    padding: 0.5rem 1rem;
                    font-size: 1.2rem !important;
                    height: auto !important;
                    cursor: not-allowed;
                }
            }

        }

    }

    &_content {
        min-height: calc(100vh - 240px);
        max-height: calc(100vh - 240px);
        overflow-y: auto;

        &_container {
            padding: 1.2rem 3rem;
            background-color: #FFFFFF;
            transition: transform 0.3s ease, box-shadow 0.3s ease;

            &:hover {
                cursor: pointer;
                transform: translateY(-1.5px);
                box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2), 0 4px 10px rgba(0, 0, 0, 0.2);
            }
        }

        &_active {
            background-color: #256BF60F;
        }

        &_name {
            color: #000000;
            text-transform: capitalize;
        }

        &_date {
            color: #767676;

        }

        &_controls {
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }

        &_uploadbtn {
            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }
        }
    }
}