.video-generation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f0f2f5;
  height: calc(100vh - 6rem);
  overflow: auto;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 40px;
    background: white;
    border-bottom: 1px solid #e8e8e8;
  }

  &__title {
    h1 {
      font-size: 24px;
      font-weight: 500;
      color: #5057d5;
      margin-bottom: 8px;
    }

    p {
      font-size: 14px;
      color: #8c8c8c;
      margin: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    background: linear-gradient(to left, #f8f4e8 1.04%, #e9eef5 44.36%);
    position: relative; /* Ensures child elements like navigation can be positioned absolutely within */
  }

  &__steps {
    margin-bottom: 10px;
    padding: 0 40px;

    .step-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;

      &.current {
        background: #000;
      }

      &.completed {
        background: #5057d5;
      }

      .anticon {
        font-size: 20px;
        color: #999;
      }

      &.current,
      &.completed {
        .anticon {
          color: #fff;
        }
      }
    }

    .ant-steps-item-title {
      font-size: 14px;
      font-weight: 500;
    }

    .ant-steps-item-tail::after {
      height: 2px;
      margin-top: 1px;
    }
  }

  &__content {
    background: white;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 25px 32px;
    margin-bottom: 32px;

    > div {
      min-height: calc(100vh - 50rem);
    }
  }

  &__navigation {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: flex-end;
    position: sticky; /* Make it stick to the bottom of the container when scrolling */
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 20px;
    padding-right: 100px;

    // If two items are present, space them between start and end
    &:has(.nav-button:nth-child(2)) {
      justify-content: space-between;
    }
    .nav-button {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;

      .anticon {
        font-size: 14px;
      }

      &[disabled] {
        .anticon {
          opacity: 0.5;
        }
      }

      .anticon-left {
        margin-right: 8px;
      }

      .anticon-right {
        margin-left: 8px;
      }
    }
  }
}
