video {
  /*width: 100%;*/
  /* max-width: 640px; */
  width: 830px;
  height: 480px;
  background: black;
}

.blink {
  animation: 1s blink linear infinite;
  font-size: 14px;
  background-color: #2b6ee3;
  color: #fff;
  padding: 5px 7px;
  margin: 10px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.localvideo {
  width: 200px;
  height: 150px;
  margin-top: 5px;
  position: absolute;
  z-index: 100;
  margin-right: 5px;
  left: 628px;
}
.controller {
  height: 3.25em;
  background: rgba(0, 0, 0, 0.06);
  width: 830px;
}
.controller-items {
  margin-top: 0.3em;
  display: -webkit-flex;
  //padding: 0.2em;
  justify-content: center;
}
.controller-item {
  width: 4.5%;
  margin-left: 1em;
  cursor: pointer;
}
.controller-item-join {
  width: 20%;
  cursor: pointer;
  margin-left: 25em;
  margin-top: 0.3em;
}
.disable-item {
  pointer-events: none;
  opacity: 0.4;
}

.ant-tooltip-inner {
  font-size: 15px !important;
}

.conference {
  position: relative;
  width: 830px;
  // overflow: hidden;
  height: 480px;
  display: flex;
  flex-direction: column;
  .streams {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    video {
      &.active {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // width: auto;
        z-index: -1;
      }

      &:not(.active) {
        /*width: 140px;
        height: 100px;
        margin: 20px;
        border: 1px solid black;
        box-shadow: 0 0 5px white;*/
        width: 128px;
        height: 73px;
      }

      &.fitlocal {
        margin-left: 49em;
        margin-top: 1.5em;
        margin-bottom: -1.5em;
        width: 128px;
        height: 88px;
      }
    }
  }
}

.hidden {
  display: none;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* background of the scrollbar except button or resizer */
.scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar::-webkit-scrollbar-button {
  display: none;
}

/* div box */

.scrollbar {
  // height: 68vh;
  margin-left: 690px;
  margin-top: 1.5em;
  width: 12vw;
  min-width: 130px;
  min-height: 0;
  overflow-y: scroll;
  flex-grow: 1;
}

.remotePlayerClass {
  height: 92px;
}
.myClass {
  position: relative;
}

.remotePlayerClass:hover .volume-controls-user {
  display: block;
}

.volume-controls-user {
  position: relative;
  bottom: 28px;
  width: 94px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}

.volume_button {
  width: 20px;
  background: transparent;
  margin-top: 0px;
  padding: 0px 0px 0px 0px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
}

.username__active {
  background: rgba(75, 79, 82, 0.6);
  padding: 4px 4px 4.1px 4px;
  font-size: 12px;
  color: #fff;
}

.username__inactive {
  background: rgba(75, 79, 82, 0.6);
  padding: 5px 4px 5px 4px;
  font-size: 10px;
  color: #fff;
}

.control_active {
  position: absolute;
  left: 5px;
  bottom: 5px;
}

button::before {
  content: attr(data-title);
  position: absolute;
  display: none;
  right: 0;
  top: -50px;
  color: #fff;
  font-weight: bold;
  padding: 4px 6px;
  word-break: keep-all;
  white-space: pre;
}

button:hover::before {
  display: inline-block;
}

.passive_hidden {
  display: none;
}
