@charset "UTF-8";
.reviews_container {
  height: 93%;
  display: flex;
  flex-direction: column;
  overflow-y: auto; }
  .reviews_container button {
    margin: 0 auto !important; }

.reviews_list {
  flex: 1; }
  .reviews_list hr {
    height: 1px;
    background-color: #ecebeb;
    border: none; }
  .reviews_list b {
    color: #136cfb; }
    .reviews_list b::after {
      content: '•';
      color: #c4c2c2;
      margin-left: 1rem; }
  .reviews_list_time {
    color: #000; }

.podcast-rating {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 32px;
  padding: 0.6rem 1.2rem; }
  .podcast-rating-body {
    padding-right: 1.2rem; }
  .podcast-rating-value {
    color: #000;
    font-size: 1.6rem;
    font-weight: 600;
    padding-right: 1.2rem; }

.btn-actions button {
  padding: 0 1.2rem;
  border-radius: 25px; }
