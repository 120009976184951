.b-pages {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;

  &__item {
    list-style: none;
    padding: 0.8rem;
    margin: 1rem 0;
    border: 1.5px solid #edf2f5;
    border-radius: 8px;
  }
}

.title {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
}

.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

.b-page {
  display: flex;
  align-items: center;

  &__handler {
    flex-shrink: 0;
    margin-right: 2rem;
  }

  &__drag {
    position: relative;
    top: 1px;
    display: block;
    width: 1.2rem;
    height: 1rem;
    opacity: 0.2;
    cursor: row-resize;
    background: linear-gradient(
      180deg,
      #000,
      #000 20%,
      #fff 0,
      #fff 40%,
      #000 0,
      #000 60%,
      #fff 0,
      #fff 80%,
      #000 0,
      #000
    );
  }

  &__info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__name {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.error_message {
  color: #ff4b55;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
