.ql-container {
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
}

.ql-editor {
  h1 {
    font-weight: 800;
    font-size: 42px;
  }

  h2 {
    font-weight: 800;
    font-size: 32px;
  }

  h3 {
    font-weight: 800;
    font-size: 26px;
  }

  h4 {
    font-weight: 800;
    font-size: 22px;
  }

  h5 {
    font-weight: 800;
    font-size: 18px;
  }

  h6 {
    font-weight: 800;
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }

  code,
  pre {
    background-color: #f0f0f0;
    border-radius: 3px;
  }

  pre {
    white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
  }

  code {
    font-size: 85%;
    padding-bottom: 2px;
    padding-top: 2px;
  }

  code:before,
  code:after {
    content: '\A0';
    letter-spacing: -2px;
  }

  pre.ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow: visible;
  }

  img {
    max-width: 100%;
  }
}
