.card-image {
  box-shadow: none;
  cursor: pointer;
  position: relative;
  border: 1.5px solid transparent;
  padding: 5px;

  .card__content {
    color: #a2afbe;

    .ant-btn {
      position: absolute;
      right: -15px;
      border: none;
      display: none;
      background: transparent;

      i {
        transform: rotate(90deg);
        color: #2699fb;
      }

      ul {
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &:hover {
    box-shadow: none;

    .ant-btn {
      display: block;
    }
  }

  .card__title {
    color: #131311;
    margin-bottom: 0px;
  }

  .card__image-thumb {
    position: relative;
    border: none;
    background: #fff;
    border-radius: 16px;
    &:hover {
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
      border: none;

      .photo-item__photographer {
        opacity: 1;
      }

      .photo-item_name {
        color: #fff;
      }
    }
  }
}

.media-page__item-on-select {
  .card__image-thumb::after {
    background: transparent;
    z-index: 1;
  }
}

.card_on-select {
  background: rgba(233, 239, 246, 0.3);
  padding: 5px;
  border-radius: 8px;
  border: 1.5px solid #e3e3e3;

  .card__image-thumb {
    background: #d5ecff;

    i {
      z-index: 3;
    }

    &::after {
      background: #fff;
    }

    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  .card__content {
    background: #d5ecff;

    .ant-btn {
      background: #d5ecff;
      display: none;
    }
  }
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 12px 0px;

  &-active {
    color: #136cfb !important;
    font-weight: 500;
  }
}

.audio-detail {
  margin-top: 30px;

  strong {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #131311;
    margin-bottom: 10px;
    display: block;
  }

  p {
    font-size: 12px;
    line-height: 15px;
    color: #404756;
  }

  button {
    margin-top: 20px;
    padding: 7px 20px;
    border: none;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
  }
}

.img-fit-into-card {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 16px;
}

.photo-item__photographer {
  max-width: calc(100% - 70px);
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 0.25rem;
  padding-left: 0.25rem;
  text-decoration: none;
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 2;
}

.photo-item__avatar {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  text-indent: -9999px;
  z-index: 2;
}

.photo-item_name {
  text-align: center;
  text-decoration: none;
  font-size: 13px;
  margin: 3px;
  font-weight: bold;
}

.wrap-icon {
  position: relative;
  display: inline-block;
  width: 100%;
  .icon {
    width: 29px;
    height: 29px;
    position: absolute;
    top: 75%;
    left: 86%;
    //background-color:rgba(0, 0, 0, 0.3);
  }
}
