.preview__header {
  height: 6rem;
  padding: 0 2rem;
  background: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  margin-bottom: 1rem;
  @media (max-width: 840px) {
    height: auto;
    display: block;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }
  .title {
    font-size: 20px;
    line-height: 6rem;
    text-transform: capitalize;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 40%;
  }
  .menus {
    display: flex;

    @media (max-width: 840px) {
      overflow: auto;
      white-space: nowrap;
    }

    .menu_item {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 3.5rem;
      i {
        font-size: 2rem;
      }

      .name {
        text-align: center;
        text-transform: uppercase;
        margin-left: 0.6rem;
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .active_menu_item {
      color: #2e70f2;
    }
  }
}
