.stream-preview {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__row {
    flex: 1;
    flex-shrink: 0;
    border-bottom: 0.2rem solid #ececec;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__chat {
    overflow: hidden;

    & > div {
      will-change: transform;
      transition: transform 0.2s;
    }
  }

  &__graph {
    overflow: auto;
    background: #fafafa;

    .b-graph {
      height: 100%;

      .b-graph__data {
        height: 100%;
      }
    }
  }

  &__chat {
    // padding: 0 2rem;
  }
}
