@import '../../styles/variables';
.ssl-open-nav {
  width: 24rem !important;
  transition: width 0.5s !important;
  .ssl-nav-item-menu {
    transition: width 0.5s !important;
  }
}
.ssl-nav-item-menu {
  margin-top: 20px !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  transition: width 0.5s;
  li {
    padding: 0 10px;
    a {
      border: 1px solid transparent;
      box-shadow: none;
      background: transparent;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 12px;
      &:hover {
        border: 1px solid transparent;
        background: $color_dark_bg;
        border-radius: 8px;
        color: $color_white;
        opacity: 0.8;
      }
    }
    a.active {
      border: 1px solid #5c646f;
      background: $color_dark_bg;
      border-radius: 8px;
      color: $color_white;

      &::before {
        background-color: transparent;
      }
      // .tXtLW {
      //   width: 5.2rem;
      // }
    }
    .none-active {
      border: 1px solid transparent !important;
      background: transparent !important;
      &:hover {
        border: 1px solid transparent;
        background: $color_dark_bg !important;
        border-radius: 8px;
        color: $color_white;
        opacity: 0.8;
      }
    }
  }
}

.help-tooltip-wrapper {
  position: absolute;
  bottom: 0;
  a {
    padding: 0 7px;
  }
}

.tooltip-wrapper {
  position: relative;
  > div {
    overflow: hidden;
  }
  svg {
    height: 20px;
    width: 20px;
    stroke: #fff;
  }
}

.tooltip {
  position: absolute;
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  background-color: $color_white;
  color: $text-color;
  padding: 10px 15px;
  border-radius: 8px;
  white-space: nowrap;
  z-index: 1;
  font-size: 12px;
  border: 1px solid #d5d9e2;
  box-shadow: 0px 4px 16px 0px #11192629;
  font-weight: 600;
  font-size: 18px;
}

.ssl-nav-item-menu .nav-item {
  position: relative;
}

.ssl-main-sidebar-wrap {
  .sider-close-icon {
    position: absolute;
    // background: #111926;
    padding: 10px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    right: -15px;
    top: 56px;
    z-index: 999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    // box-shadow: 0px 2px 20px 0px #11192666;
    border: 1px solid #eff0f0;
  }
}

.main-header-logo {
  .ssl-logo-source {
    background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
    height: 7.2rem;
    display: flex;
    align-items: center;
    padding-left: 20px;
    &.fix-width-logo {
      svg {
        width: 2.3rem;
      }
    }
    &.logo-no-gap {
      padding-left: 0px;
    }
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
