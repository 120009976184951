@import '../../../../styles/variables';

.upload-form {
  > div {
    margin-bottom: 10px;
  }

  &__textarea {
    position: relative;

    > textarea {
      resize: none;
      padding-bottom: 20px;
    }

    > label {
      position: absolute;
      right: 12px;
      bottom: 5px;
      color: map-get($colors, darkGreyFour);
      font-size: 10px;
    }
  }

  &__submit-button {
    text-align: right;
    display: initial;

    &__cancel-button {
      border-radius: 15px;
      display: none;
    }

    &__save-button {
      border-radius: 15px;
      text-transform: capitalize;
      display: initial;
    }

    &__hidden {
      &__cancel-button {
        display: initial;
      }

      &__save-button {
        display: none;
      }
    }
  }

  &__checkbox__container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
      margin-left: 0 !important;
    }
    div:first-of-type {
      margin-bottom: 1rem;
    }
  }

  .ant-form-explain-holder {
    display: none;
  }
}

.visible-button {
  text-align: right;
  display: initial;

  .cancel-button {
    display: none;
    border-radius: 15px;
  }

  .save-button,
  .remove-button {
    display: initial;
    text-transform: capitalize;
    margin-left: 10px;
  }

  &.hidden {
    .cancel-button {
      display: initial;
    }

    .save-button,
    .remove-button {
      display: none;
    }
  }
}

.custom-select-tag {
  > div {
    padding: 5px 5px 10px 5px;

    &:focus,
    &:active {
      box-shadow: none;
      border-color: #d9d9d9;
    }
  }

  ul {
    li {
      &.ant-select-selection__choice {
        background: map-get($colors, white);
        border: 1px solid map-get($colors, blue);
        border-radius: 13px;
        color: map-get($colors, blue);
        padding: 0 10px;
        margin-left: 0;

        > span {
          display: none;
        }
      }
    }
  }
}

// Hide dropdown for select tags
.disable-select-dropdown {
  ul {
    display: none;
  }
}

.error_message {
  color: #ff4b55;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
