@import "../../../../styles/variables";


.private-public-modal {

  &__text {
    text-align: center;
    font-size: 22px;
  }

  &__list-container {
    border-top: 2px solid map-get($colors, darkBorder);
    max-height: 520px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 30px;
  }

  &__list {
    padding: 5px 0px 20px;
  }

  &__item {
    display: flex;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 0.5px solid map-get($colors, darkGreyTwo);

    &:last-child {
      border-bottom: none;
    }
  }

  &__thumbnail {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;

    &__details {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 11px;

      > span {
        margin-right: 10px;
      }
    }

    &__image {
      max-width: 100%;
      margin-bottom: 15px;
      max-height: 200px;
      border: 0.5px solid #fafafa;
    }

    &__audio-video {
      width: 270px;
      height: 145px;
      background-color: #e8f0ff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
    }

    &__media-type {
      background-color: #EFEFEF;
      color: #777777;
      border-radius: 10px;
      padding: 2px 10px;
    }
  }

  &__footer {
    border-top: 2px solid map-get($colors, darkBorder);
    text-align: right;
    padding-top: 10px;

    > button {
      border: none;
      padding: 0 50px !important;
      margin-left: 10px;
    }
  }

  &__item-container {
    width: 100%;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;

    &__thumb {
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid map-get($colors, darkGreyTwo);

      > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__audio-video {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: map-get($colors, blue)
    }
  }

  .disclaimer_label {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }

  .label {
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: .4rem;
  }


}

.add-keywords-section {
  margin-bottom: 10px;
  margin-top: 10px;
}
