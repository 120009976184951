// styles.scss

.choice-modal {
  .ant-modal-content {
    border-radius: 8px; // Example border radius
    padding: 20px;
    text-align: center;
  }

  > p {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
  }

  .choice-modal__items {
    display: flex;
    justify-content: space-around;
    gap: 30px;
  }

  .choice-modal__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    border: 1px solid #d7d4d4;
    border-radius: 20px;
    padding: 10px;
    width: 400px;
    height: 200px;

    i {
      font-size: 30px;
    }

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
    }

    p {
      text-align: center;
      height: 30px;
    }

    &:hover {
      border: 1px solid #5057d5;

      h3,
      p,
      i {
        color: #5057d5;
      }
    }
  }
}
