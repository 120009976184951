.comment-input .ant-dropdown {
  position: absolute !important;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.comment-input .ant-input {
  position: relative;
}
