.maze-selected-image {
  width: 100%;
  height: 300px;
  background: #f4f5fa;
  object-fit: cover;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 1px 0.5px rgb(165, 164, 164);
  }

  &:focus {
    border: 2px solid rgb(86, 121, 218);
  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 15px;
    margin-top: 5px;
  }
}
.focus-image {
  box-shadow: 0 0 2px 1.5px rgb(90, 117, 235);
}

.maze-image-list-item {
  position: relative;
}
