.action-list {
  padding: 0px 30px 0px 40px;

  audio {
    width: 100%;
  }

  .img-fit-into-card {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 16px;
  }

  ul {
    display: flex;
    flex-direction: row-reverse;

    li {
      list-style: none;
      display: inline-flex;
      margin: 0px 10px;
      justify-content: right;
    }
  }
}
