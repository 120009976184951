.badge_modal_wrap {
  position: relative;
  overflow: hidden;
  height: 700px;
  .text_position {
    display: flex;
    gap: 10px;
    .text_picker {
      background: #edf2f5;
      padding: 10px;
      width: 50px;
      height: 40px;
    }
    .badge-horizontal {
      width: 50%;
      h3 {
        font-size: 14px;
        font-weight: 500;
      }
      .horizontal-wrap,
      .vertical-wrap {
        display: flex;
        align-items: center;
        border: 1.5px solid #e3e3e3;
        border-radius: 8px;
        overflow: hidden;
        .ant-input-number {
          border: none;
          &:focus,
          &:hover {
            outline: none;
            border: none !important;
            box-shadow: none;
          }
        }
      }
    }
  }
  .font_size_picker {
    // display: flex;
    // align-items: center;
    .badge-number {
      .ant-input-number-handler-wrap {
        border-radius: 8px;
      }
    }
  }
  .badge_color_picker.picker-container {
    font-size: 16px;
    height: 40px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #6363fa;
    border-radius: 8px;
    font-size: 12px;
    cursor: pointer;
    padding: 8px 20px;
    margin: 0;
  }
  .btn-upload {
    position: relative;
    .btn {
      background: #58bb68;
    }
    input[type='file'] {
      position: absolute;
      opacity: 0;
      z-index: 0;
      max-width: 100%;
      height: 100%;
      display: block;
    }
  }
  .badge-text-setting {
    .text_decoration {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      width: fit-content;
      border-radius: 8px;
      height: 40px;
      border: 1px solid #a3b6b9;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        cursor: pointer;
        color: black;
        border: 1px solid #a3b6b9;
        background: #edf2f5;
        cursor: pointer;
        &:hover {
          background: rgb(181, 181, 182);
        }
      }
      .checked {
        background: rgb(163, 182, 185);
      }
    }
    .text_position {
      display: flex;
      flex-direction: row;
    }
    .slant-wrap {
      width: 50%;
      .slant {
        display: flex;
        flex-direction: row;
        border: 1px solid #e3e3e3;
        height: 40px;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;

        .slant_picker {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #edf2f5;
          padding: 4px;
          overflow: hidden;
          cursor: pointer;
        }
        .slant_icon {
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgb(255, 255, 255);
          cursor: pointer;
        }
      }
    }

    .badge-number {
      .ant-input-number {
        width: 100%;
        border-radius: 8px;
        height: 40px;
        margin-bottom: 10px;
      }
    }
    .text_radius {
      .ant-input-number {
        width: 100%;
        border-radius: 8px;
      }
    }
    .text-stunt-wrap {
      display: flex;
      gap: 10px;
      align-items: center;
      margin: 10px 0;
    }
  }
  .user_upload_image {
    height: 100px;
    width: 100px;
    margin: 20px;
    object-fit: contain;
  }
  .badge-item-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.badge_modal_title {
  padding: 0 20px;
  font-weight: bold;
  font-size: 23px;
  line-height: 29px;
  text-transform: capitalize;
  color: #000000;
}

.badge_modal_content {
  display: flex;
  flex-direction: row;
  // max-height: 541px;
  .badge_create_left {
    width: 66.66%;
    // max-height: 650px;
    // min-height: 650px;
    // overflow: auto;
    margin-top: 15px;
  }
  .badge_create_right {
    width: 33.33%;
    .badge_modal_title {
      padding-left: 0;
      margin-bottom: 20px;
    }
    .badge_history {
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      height: 600px;
      border-radius: 16px;
      border: 1px solid #e3e3e3;
      padding-bottom: 1.5rem;
      &.badge-history-active {
        height: 420px;
        margin-top: 6rem;
      }
      .title {
        padding: 1.5rem;
        margin-bottom: 0px;
        border-bottom: 1px solid rgb(227, 227, 227);
        background: rgba(233, 239, 246, 0.3);
        border-radius: 16px 16px 0px 0px;
      }
      .history_items {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 1.5rem;
        .history_item {
          min-width: 100%;
          height: 65px;
          display: flex;
          margin-bottom: 10px;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
          background: #fff;
          cursor: pointer;
          padding: 0.8rem;
          margin: 1rem 0;
          border: 1.5px solid #edf2f5;
          border-radius: 8px;
          &_details {
            display: flex;
            justify-content: space-evenly;
            flex: 1 1;
            flex-grow: 1;
            min-width: 0;
          }
          img {
            max-height: 45px;
            min-width: 65px;
            margin: 10px;
            object-fit: contain;
          }
          &_info {
            flex: 1;
            height: 100%;
            display: flex;
            margin-left: 5px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-top: 10px;
            flex-grow: 1;
            min-width: 0;
            overflow: hidden;
            h3 {
              font-weight: bold;
              font-size: 14px;
              line-height: 13px;
              color: #000000;
              margin-bottom: 3px;
              word-break: break-all;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            p {
              font-size: 14px;
              color: #000000;
              white-space: normal;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              max-width: 96%;
            }
          }
          &_action {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 20px;
            justify-content: space-evenly;
            align-items: center;
            background: #f4f4f4;
            &_img {
              height: 50%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background: rgb(218, 217, 217);
              }
            }
            img {
              min-width: 10px;
              max-height: 10px;
            }
          }
        }
      }
    }
  }
}

.badge_container {
  padding: 20px;

  .left_panel {
    .ant-tabs-nav {
      background: #fff;
      width: 100%;
      position: relative;
      > div {
        display: flex;
        justify-content: space-between;
        background: #fff;
      }
    }
    .tabs {
      .tab {
        .badge-container {
          display: flex;
          align-items: center;
          justify-content: flex-start !important;
          overflow-x: auto !important;
          white-space: nowrap;
          gap: 10px;
          width: 100%;
          height: 100px;
          margin-bottom: 1rem;
          overflow-y: hidden;
          .badge {
            &__icon {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 1rem 2rem;
              border: 1.5px solid #e3e3e3;
              border-radius: 8px;
              transition: 0.2s;
              margin-bottom: 1rem;
              &:hover {
                border: 1.5px solid #5057d5;
              }
              &.ant-radio-button-wrapper-checked {
                border: 1.5px solid #5057d5;
              }
            }
          }
        }
      }
    }
    .ant-tabs-content {
      margin-top: 0;
      margin-bottom: 0;
      background-color: #fff;
      height: auto;
      color: black;
    }
    .tab_icon {
      width: 80px;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: space-evenly;
      .badge-img-template {
        width: 50px;
        height: 50px;
        border: 1.5px solid #edf2f5;
        border-radius: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
      }
      span {
        font-size: 14px;
        color: #000000;
        font-weight: 600;
      }
    }
    .ant-tabs-tab {
      margin: 0;
      padding: 0;
    }
    .ant-tabs .ant-tabs-left-content {
      padding-left: 0;
    }
    .ant-tabs-tab-active {
      .tab_icon {
        background-color: #fff;
        .badge-img-template {
          border: 1.5px solid #5057d5;
        }
      }
    }
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 0px;
      height: 0px;
      background-color: 'white';
      content: '';
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      z-index: 1;
      color: transparent;
      border: 1px solid #000000;
      -webkit-box-shadow: 0 0 0 0 transparent;
      box-shadow: 0 0 0 0 transparent;
    }
    .badge__icon {
      width: 50px;
      height: 35px;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      > span {
        position: absolute;
        padding: 10px;
        &:last-child {
          position: absolute;
          padding: 10px;
        }
      }
      img {
        width: 30px;
        height: 30px;
      }
      &:hover {
        background-color: whitesmoke;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .col {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
  }
}
.below-panel {
  display: flex;
  gap: 20px;
  margin-top: 2rem;
}
.canvas-wrap {
  width: 50%;
  position: relative;

  .canvas {
    padding: 20px;
    width: 100%;
    display: flex;
    border: 1.5px solid #5057d5;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    height: 100%;
    canvas {
      height: 200px;
      width: 200px;

      // background-image: url('../../../../../../../assets/tsp_back.svg');
    }
    &_info {
      display: flex;
      gap: 1rem;
      .menu {
        border: 1px solid #dbd8d8;
        padding: 8px 10px;
        border-radius: 8px;
        position: relative;
        width: 120px;
        margin-top: 10px;
        overflow: hidden;
        font-size: 9px;
        span {
          position: absolute;
          background-color: red;
          top: 0;
          right: 0;
          bottom: 0;
          color: white;
          width: 35px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          cursor: pointer;
          &:hover {
            background-color: rgb(238, 87, 87);
          }
        }
      }
    }
  }
}
.detail {
  padding: 22px 0;

  width: 50%;

  &.detail.badge-add-active {
    width: 100%;
    height: 170px;
    .details {
      textarea {
        height: 40px;
      }
    }
  }

  input {
    border: none;
    background: #ffffff;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
    padding: 5px;
  }
  .title {
    input {
      height: 36px;
    }
  }
  .details {
    margin-top: 20px;
    textarea {
      height: 82px;
      border: none;
      background: #ffffff;
      border-radius: 5px;
      border: 1.5px solid #e3e3e3 !important;
      &:focus {
        outline: none;
      }
      padding: 5px;
      resize: none;
    }
  }
  .d-col {
    display: flex;
    flex-direction: column;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      margin-bottom: 5px;
      &::after {
        content: ' *';
        color: red;
      }
    }
  }
  .buttons {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      margin-left: 10px;
      height: 25px;
      padding: 5px 35px;
      background: #aaaaaa;
      border-radius: 50px;
      cursor: pointer;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
}
.save_badge {
  margin: 40px auto 0 auto;
  width: 170px;
  height: 30px;
  background: #2e70f2;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  &:hover {
    background: #2e70f2;
    color: white;
  }
}
.user_icon {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  overflow-x: auto !important;
  white-space: nowrap;
  gap: 10px;
  width: 100%;
  margin-bottom: 0rem;
  overflow-y: hidden;
  .badge__icon {
    border: 1.5px solid #e3e3e3;
    border-radius: 8px;
    transition: 0.2s;
    margin-bottom: 2rem;
    width: 60px;
    height: 40px;
    padding: 0rem 2rem;
    position: relative;
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.badge-chage-sec {
  width: 50%;
  .text_env {
    .ant-input-number {
      height: 40px;
      width: 100% !important;
      .ant-input,
      .ant-input-number-input,
      input {
        margin-bottom: 10px;
        height: 40px;
      }
    }
  }
  .badge-text-wrap {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    > div {
      width: 50%;
    }
  }
}
.badge_color_picker {
  height: 40px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #6363fa;
  border-radius: 8px;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 20px;
}

.icon-color-wrap {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 2rem 0;
  .btn-upload {
    .btn {
      height: 40px;
      padding: 10px 30px;
    }
  }
}
