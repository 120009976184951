.info-modal-header {
  display: flex;
  //   justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  h3 {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }

  .info-modal-header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    img {
      width: 40px;
      height: 30px;
      margin-right: 1rem;
    }
    h2 {
      font-size: 20px;
      color: #000000;
      font-weight: 700;
      text-align: center;
      line-height: 25px;
      text-transform: uppercase;
    }
  }

  .information-react-player {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }

  .info-modal-button {
    border-radius: 100px !important;
    background: #2d6ff2;
    // padding: 1rem;
    width: 186px;
    height: 45.13px;
  }
}

.information-modal .ant-modal-close-x {
  padding: 12px 12px 0px 0px;
  font-size: 28px;
}
