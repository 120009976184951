@import '../../../styles/variables';

.event {
  &__inner {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 992px) {
      flex-direction: row;
    }
    @media screen and (max-width: 840px) {
      display: block;
    }
  }

  &__content {
    flex: 1;
    width: 70%;
    margin-right: 3.2rem;
  }

  &__sidebar {
    width: 30%;
    flex-shrink: 0;
    @media screen and (max-width: 840px) {
      width: 100%;
    }
  }

  &-sidebar {
    &__section {
      padding-bottom: 3rem;

      &:last-child,
      &_clear {
        padding-bottom: 0;
      }
    }
  }

  &-stream {
    position: relative;
    width: 854px;
    height: 480px;

    &__cover {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: #ececec;
      width: 97%;
      height: 100%;

      .__schedule {
        background-color: map-get($colors, blue);
        color: white;
        padding: 0.5rem 2rem;
        width: 25.5rem;
        font-size: 1.6rem;
        letter-spacing: -0.1rem;
      }

      .__count_down {
        background-color: white;
        color: #0068ff;
        position: absolute;
        bottom: 0;
        padding: 0.5rem 1.5rem;
        margin: 1rem;
        width: 97.5%;
        font-weight: bold;
        font-size: 1.6rem;
        flex-direction: row;
        display: flex;
        align-items: center;

        .icon_message {
          position: absolute;
          right: 0;
          margin-right: 1.5rem;
          width: 2.8rem;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
      }
    }

    &__video {
      width: 100%;
      height: 0;
      padding: 0 0 56.25% 0;

      &_NOT_STARTED {
        background: #333;

        &::after {
          content: attr(data-message);

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          display: block;
          padding: 0.8rem 1.6rem;
          color: #fff;
          border: 1px solid #fff;

          font-weight: 600;
          font-size: 1.4rem;
          border-radius: 2rem;
        }
      }

      &_PROCESSING {
        background: #333;

        &::after {
          content: attr(data-message);

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          display: block;
          padding: 0.8rem 1.6rem;
          color: #fff;

          font-weight: 600;
          font-size: 1.4rem;
          text-align: center;
        }
      }

      &_RUNNING {
        &::after {
          content: attr(data-message);

          position: absolute;
          top: 1.6rem;
          right: 1.6rem;

          display: block;
          padding: 0.4rem 0.8rem;
          background: #ff6332;
          color: #fff;

          font-size: 1.2rem;
          text-transform: uppercase;
          border-radius: 2rem;
        }
      }
    }
  }

  &__head {
    padding: 0;

    .wrap_video_graph {
      position: relative;

      .legendColor {
        width: 3rem;
        height: 1.5rem;
        border-radius: 0.2rem;
        margin-right: 2rem;
        margin-left: 2rem;
      }
    }

    .input-range {
      &__slider {
        background-color: '#0068ff';
        border-radius: 0;
        height: 1.5rem;
        width: 0 !important;
        border-left: 0.1rem solid #0068ff;
        right: -1px;
      }

      &__track {
        background-color: white;
      }

      &__track--background {
        height: 100%;
        position: relative;
        top: 0;
      }

      &__track--active {
        height: 100%;
        // background-color: map-get($colors, blue);
        background-color: #d7e7ff;
        border-right: 0.1rem solid map-get($colors, blue);
        border-radius: 0;
      }

      // &__slider-container {
      // display: none
      // }

      &__label--max {
        display: none;
      }

      &__label--min {
        display: none;
      }

      &__label-container {
        // display: none;
        color: black;
        bottom: -2.5rem;
        font-size: 1.2rem;
        position: relative;
      }
    }
  }

  &__wrap_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
  }

  &__past_Heading {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.8rem;
    font-weight: 600;
    margin: 0;
    color: map-get($colors, darkGreyOne);
  }

  &__heading {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.8rem;
    font-weight: 600;
    color: black;
    margin: 0;
  }

  &-users {
    display: flex;
    align-items: center;

    &__icon {
      font-size: 1.2rem;
      padding-right: 0.8rem;
    }

    &__text {
      font-size: 1.2rem;
      text-transform: uppercase;
    }
  }
}

.hosts {
  &__item {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
}

.host {
  display: flex;
  align-items: center;

  &__thumb {
    width: 3.2rem;
    height: 3.2rem;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
  }

  &__name {
    padding-left: 1.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.wrap_no_profile {
  padding: 5px 10px;
  // border: 1px solid map-get($colors, red);

  span {
    color: map-get($colors, red);
  }
}
