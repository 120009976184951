.file-upload {
  &__title {
    padding: 1rem 0 1rem 2rem;
    width: 100%;
    border: 2px solid rgb(230, 229, 229);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: rgb(250, 250, 250);
    font-weight: bold;
    color: #000;

    span {
      margin-left: 0.5rem;
    }

    .anticon {
      font-weight: bold;
    }
  }

  &__button {
    width: 100%;
    min-height: 18rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2rem;
    padding: 0.5rem 3rem;
    border: 2px solid rgb(230, 229, 229);
    border-top: none;
    background: rgb(250, 250, 250);

    &:last-of-type {
      margin-bottom: 1rem;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 2rem;
      img {
        height: 5rem;
        border-radius: 5px;
        object-fit: contain;
      }

      .upload-pdf-title {
        color: #000;
        font-weight: bold;
        word-break: break-word;
      }
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: center;

      &-delete {
        height: 2rem;
        cursor: pointer;

        &:hover {
          background: rgb(223, 222, 222);
        }

        &:focus {
          background: rgb(170, 168, 168);
        }
      }

      span {
        color: rgb(22, 73, 212);
        font-weight: bold;
        margin-right: 3rem;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.file-upload__container {
  .file-upload-content {
    border: 2px solid #a0a0a0;
    width: 100%;
    border-width: 1.5px;
    border-color: #e3e3e3;
    border-style: solid;
    border-radius: 8px;
    cursor: pointer;
    min-height: 25rem;
    padding: 2rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.file-uploaded {
      justify-content: space-between;
      padding: 1rem;
      align-items: flex-start;
      position: relative;

      .file-upload-inner {
        max-width: 85%;
        width: 100%;
        min-height: 23rem;
        max-height: 23rem;
        padding-right: 1rem;

        .file-upload__item {
          background: transparent;
          border: none;
          border-bottom: 1px solid #e3e3e3;
          padding: 0.5rem;
        }
      }

      .file-upload-btn {
        max-width: 15%;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: initial;
        background: #edf2f5;
        border-radius: 0px;
      }
    }

    .file-upload__button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3rem;
      border-radius: 8px;

      .attach-file-left {
        display: flex;
        align-items: center;
        gap: 20px;
        text-align: left;
      }
    }
  }
}
