.next-lesson-button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  background-color: #5057d5;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

  &:hover {
    background-color: #5057d5;
  }

  gap: 10px;

  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    line-height: 22px;

    span {
      font-size: 14px;
      color: #dbeafe;
    }

    h3 {
      font-size: 16px;
      margin: 0;
      font-weight: normal;
      color: #fff;
    }
  }

  .anticon {
    font-size: 16px;
    margin-left: 8px;
  }
}

.course-preview__head-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  gap: 2rem;

  &__icon {
    padding: 10px;
    background-color: #f4f5fa;
    border-radius: 10px;
    font-size: 3rem;

    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  h2 {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  p {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #a3a2a2;
  }
  h3 {
    margin: 0;
    font-weight: bolder;
    font-size: 14px;
    line-height: 17px;
    color: #5057d5cc;
  }
}
