.user-table-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &-title {
    font-size: 1.25rem;
    color: #287bf6;
  }
  &-host {
    opacity: 0.8;
  }
  &-views {
    opacity: 0.5;
    font-size: 10px;
  }
  &-created {
    opacity: 0.5;
    font-style: italic;
    font-size: 10px;
  }
}
