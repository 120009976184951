.org-group-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  .org-group-list {
    .org-group-btn {
      display: flex;
      align-items: center;
      padding: 0.5rem 2rem;
      border: 1.5px solid #e3e3e3;
      transition: border-color 0.2s;
      cursor: pointer;
      border-radius: 30px;
      &.active {
        color: #5057d5;
        border: 1.5px solid #5057d5;
      }
      .org-group-initial {
        background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        height: 4rem;
        margin-right: 1.6rem;
        border-radius: 100%;
      }
    }
  }
}
.org-of-marketplace {
  .ant-modal-content {
    border-radius: 16px;
    width: 600px;
    min-height: 400px;
    max-height: 400px;
    padding: 2rem;
    .ant-modal-header {
      color: #212121;
      .ant-modal-title {
        color: #212121;
        font-weight: 600;
        font-size: 18px;
      }
    }
    .org-group-item {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .ant-modal-body {
      padding: 1rem 3rem 2rem;
      line-height: 2;
      margin-top: 0;
      margin-bottom: 1rem;

      .org-all-list {
        min-height: 240px;
        max-height: 240px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
    .ant-modal-footer {
      border: none;
      position: absolute;
      bottom: 1rem;
      display: flex;
      align-items: center;
      right: 0;
      left: auto;
      width: 100%;
      button.ant-btn {
        width: 100%;
      }
    }
  }
}
