@import '../../../../../styles/variables';

.create-course {
  display: flex;
  justify-content: space-between;

  &__content {
    flex: 1;
    padding-right: 3.2rem;
    // max-width: 100rem;
  }

  &__sidebar {
    padding-top: 2.2rem;
    width: 30rem;
    flex-shrink: 0;

    @media screen and (max-width: '714px') {
      flex-wrap: wrap;
      width: 65vw;
    }
  }

  @media screen and (max-width: '714px') {
    flex-wrap: wrap;
    display: block;
  }
}

.b-theme {
  padding-top: 2rem;
  text-align: center;

  &__button {
    margin-bottom: 2rem;
  }

  &__text {
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
  }
}

.lesson_title {
  max-width: 100px;
  overflow: hidden;
  word-break: keep-all;
}

.error_message {
  color: #ff4b55;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.button-marker_place-div {
  display: flex;
  padding-top: 0px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  @media screen and (max-width: '420px') {
    margin-bottom: 20px;
    justify-content: flex-start;
  }
}

.no_bottom_margin {
  margin-bottom: 0px;
}

.canva-editor-items {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 100%;
  height: 88.2px;
  gap: 20px;

  i {
    border-radius: 100%;
    background: #edf2f5;
    padding: 8px;
    color: #5b6ee2;
  }

  .item {
    width: 50%;
    text-align: left;
    height: 45px;
    border-radius: 8px;
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media screen and (max-width: '470px') {
      margin-bottom: 10px;
    }
  }

  .text {
    padding-top: 13px;
    font-size: 13px;
  }

  @media screen and (max-width: '470px') {
    flex-direction: column;
    height: 150px;
    align-items: flex-start;
  }
}

.upload-file-scorm {
  position: relative;
  padding: 1rem 0;
  min-width: 200px;
  width: 200px;
  flex: 1;
  margin: 5px;

  &__title {
    text-transform: uppercase;
  }

  &__weight {
    font-weight: 700;
    text-align: center;

    &__complete {
      text-transform: uppercase;
    }

    &__canceled {
      text-transform: uppercase;
      color: map-get($colors, red);
    }
  }

  &__percent-border {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1.8rem;
    border: 0.5px solid #efefef;
  }

  &__percent {
    background: map-get($colors, green);
    width: 0;
    height: 1.8rem;
    transition: width 0.2s;

    &__cancel {
      background: map-get($colors, red);
    }
  }
}

.is-mobile-edit-item {
  > div {
    @media (min-width: 1440px) {
      width: 100%;
    }
  }

  .canva-editor-items {
    @media (max-width: 1439px) {
      margin-top: 0;
    }
  }
}
