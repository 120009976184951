.quiz-table {
  margin: 30px 50px;
  text-align: center;
  overflow: scroll;
}
.quiz-table td {
  padding: 10px 5px;
  font-size: 14px;
  line-height: 16px;
  color: #6c7b8a;
}
.quiz-table td:first-child {
  color: black;
}
.quiz-table tr:nth-child(even) {
  background-color: #f6f6f6;
}
.loading {
  text-align: center;
  padding: 5rem;
}
.question {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 20px 10px;
}
.answer {
  margin-left: 20px;
  font-size: 14px;
  line-height: 16px;
}
.box {
}
.quiz-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 5px 0 20px 0;
}
.quiz-status {
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  padding: 20px;
}
.quiz .username {
  font-size: 14px;
  line-height: 18px;
  color: #131311;
  /*display: inline-block;*/
}
.quiz .submit {
}
.quiz {
  padding: 20px;
  background-color: #fff;
  margin: 0 20px;
}
.progress {
  display: flex;
  height: 3rem;
  overflow: hidden;
  font-size: 1rem;
  background-color: #fff;
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  background-color: #bdcadb;
}
.quiz .ant-tabs-nav .ant-tabs-tab {
  padding: 25px 20px;
}
.ant-collapse-item {
  margin-top: 10px;
  border: 0.5px solid #bdbdbd;
  border-radius: 5px;
}
.ant-collapse-content-box {
  display: none;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 5px;
}
.report-type-select {
  width: 85%;
}
.report-type-select .ant-select-selection {
  background: #ffffff;
  border: 2px solid #bce0fd;
  color: #7fc4fd;
}
