.quiz-choice {
  &-container {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  border-radius: 50px;
  padding: 1rem 3rem;
  background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%);
  color: #fff;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.quiz-create {
  .ant-input-number-lg {
    width: 100%;
    border-radius: 8px;
  }
  .ant-modal-footer {
    border: none;
  }
}
