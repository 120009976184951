// VideoStyle.scss
.video-style-selector {
  .header {
    h1 {
      color: #333;
      font-size: 18px;
      font-weight: 600;
    }

    p {
      color: #666;
      font-size: 14px;
    }
  }
  .shot-list {
    &-cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 32px;
      margin: 30px 0;

      .header {
        h1 {
          color: #333;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          color: #666;
          font-size: 14px;
        }
      }

      .shot-list-card {
        border: 1px solid #dfdfdf;

        &:hover {
          border: 1px solid #5057d5;
        }
      }

      .shot-list-card.selected {
        border: 1px solid #5057d5;
        outline: 2px solid #5057d5;
        position: relative;

        .ant-card-body {
          background: #5057d5;

          .ant-card-meta {
            &-title {
              color: white;
            }

            &-description {
              color: white;
            }
          }
        }

        &::after {
          content: '\2713';
          position: absolute;
          width: 24px;
          height: 24px;
          color: white;
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          bottom: 3rem;
          right: 3%;
          background-color: #5057d5;
          border-radius: 50%;
          box-shadow: 0 0 0 1px #ffffff;
        }
      }
    }

    &-card {
      transition: none;
      cursor: pointer;
      overflow: hidden;
      border-radius: 25px;

      .ant-card-body {
        padding: 20px;
        height: 100%;
        border-top: 1px solid #c9c9c9;
      }

      &-image {
        min-height: 150px;
        width: 100%;
        aspect-ratio: 4 / 3;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-position: top;
          object-fit: cover;
          transform: scale(1.1);
        }
      }
    }
  }
}
