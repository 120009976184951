.shl-primary-btn,
.ant-btn-primary {
  background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%) !important;
  color: #fff;
  border-radius: 30px;
  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(180deg, #698bf2 0%, #5057d5 100%) !important;
    opacity: 0.8;
    color: #fff;
  }
}
.ant-btn {
  border-radius: 8px;
}

.shl-secondary-btn {
  background-color: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}
