@import './vendor/quill.css';
@import './variables.scss';
@import './overrides.scss';
@import './typo.scss';
@import './components/quill';
@import './button.scss';
@import url('https://fonts.googleapis.com/css?family=Mulish&display=swap');

*:not(input, textarea),
*:focus:not(input, textarea) {
  user-select: auto;
}

html {
  font-size: 62.5%;
}

.zsiq_floatmain {
  bottom: 70px !important;
  right: 60px !important;
}

body {
  font-size: 1.4rem !important;
  font-weight: map-get($base, fontWeight);

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  font-family: 'Mulish', sans-serif;
}

.section {
  padding: 3.2rem;

  @media (max-width: 840px) {
    padding: 3.2rem 1.5rem;
  }

  // &_light {
  //   background: map-get($colors, light);
  // }

  &_divider {
    border-top: 1px solid #ececec;
  }
}

.head {
  padding-bottom: 2.4rem;

  &_nopadding {
    padding: 0;
  }

  &__title {
    @extend h1;
    font-weight: 600;
    margin-bottom: 0.4rem;
    color: #212121;
  }

  &__description {
    @extend h3;
    font-weight: 400;
    font-size: 14px;
    color: #676973;
  }
}

.controls {
  display: flex;
  justify-content: space-between;

  &__part {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:last-child) {
      padding-right: 1.6rem;
    }
  }
}

.media-page {
  &__head {
    padding-bottom: 3.2rem;
  }
}

.p-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  background: #fff;
  border-top: 1px solid #eee;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1.4rem 3.2rem 1.4rem 9.6rem;

    &_justify {
      justify-content: space-between;
    }
  }

  &__col {
    display: flex;
    align-items: center;
  }

  &__item {
    &:not(:first-child) {
      padding-left: 2rem;
    }

    .ant-btn {
      height: 40px;
    }
  }
}

.video {
  position: relative;
  padding-top: 56.25%;

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}

.static-page {
  padding: 4rem 0;
}

.container {
  padding: 0 2rem;
  max-width: 128rem;
  margin: 0 auto;
}

.p-tabs {
  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs-nav {
    width: 100%;
    box-sizing: border-box;

    &>div:first-child {
      width: 100%;

      display: flex;
      align-items: center;

      .ant-tabs-tab {
        flex: 1;
        margin: 0;
        text-align: center;

        font-family: 'Mulish', sans-serif;
        font-size: 1.4rem;
        font-weight: 700;

        &-active {
          color: #777;
        }
      }
    }
  }
}

.shl-workshop-create {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  // scrollbar-width: none;
}

.button-plus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // margin-right: -13px;
  padding: 2rem 7px;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  transition: 0.2s;
  margin-bottom: 2rem;
  cursor: pointer;

  @media (max-width: 840px) {
    padding: 2rem;
    justify-content: inherit;
  }

  &+& {
    margin-left: 2rem;
  }

  &__icon {
    position: relative;
    width: 3rem;
    height: 3rem;
    background: #edf2f5;
    border-radius: 100%;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 1.2rem;
      height: 0.2rem;
      background: #5b6ee2;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  &__text {
    font-weight: 600;
    margin-left: 1rem;
  }

  &:hover {
    border-color: rgba(map-get($colors, darkGreyOne), 0.8);
  }
}

// MAHESH_ADDED

.btn-success {
  background: #1dba61;
  color: #fff;

  &:hover {
    background: #1dba61;
    color: #fff;
  }
}

// SUPRIM_ADDED
.create_course__startmodal {
  border-radius: 5px;
  min-width: 70vw;
  padding: 0rem 24px;

  &_content {
    padding: 12px;
    padding-top: 24px;
  }

  &_radiogroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;

    label {
      margin-top: 1rem;
      height: auto !important;
      min-height: 40px;
      display: flex;
      align-items: center;
      line-height: 1.5 !important;
      padding: 5px 12px;
    }
  }
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

@media (max-width: 840px) {
  .workshop-wrapper {
    >div {
      >div {
        width: 100%;

        &:nth-child(2) {
          text-align: left !important;
          margin-bottom: 20px;
        }

        .button-plus {
          margin: auto 10px;
          width: 90%;
          justify-content: flex-start;
          padding: 2rem 1.5rem;
        }

        .button-plus+.button-plus {
          margin-left: 0;
          margin: 10px;
          width: 90%;
          justify-content: flex-start;
          padding: 2rem 1.5rem;
        }
      }
    }
  }

  .workshop-head-wrapper {
    >div {
      &:nth-child(2) {
        display: block !important;

        >div {
          &:nth-child(1) {
            overflow-x: scroll;
            overflow-y: hidden;
            height: 50px;
            white-space: nowrap;
            margin-bottom: 20px;
          }
        }
      }

      &:nth-child(3) {
        >div {
          padding: 1.3rem 0;

          >div {
            display: block;
            padding: 0;
            margin-bottom: 35px;

            >div {
              display: block;
              width: 100%;
              margin: 0;
              padding: 15px 10px;

              &:nth-child(1) {
                width: 100%;
                min-width: 100%;
                height: 150px;
              }

              &:nth-child(3) {
                padding: 0 10px;
              }

              &:nth-child(4) {
                >div {
                  flex-direction: inherit !important;
                  gap: 50px;

                  >a,
                  >div {
                    border: 1px solid #0068ff;
                    border-radius: 100%;
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 0.5rem;

                    div {
                      justify-content: center;
                      display: flex;
                      align-items: center;
                    }

                    svg {
                      width: 1.2rem;
                    }
                  }
                }
              }

              &:nth-child(3) {
                >div {
                  &:nth-child(2) {
                    >div {
                      flex-direction: inherit;
                      gap: 20px;

                      span {
                        width: auto;
                        color: #67686e;
                        font-weight: 700;
                        margin-top: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .training-wrapper {
    input {
      width: 100% !important;
    }
  }
}

.marketplace-head {
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;
}

.ant-modal-wrap {
  background: linear-gradient(106.91deg,
      rgba(248, 244, 232, 0.8) 1.04%,
      rgba(233, 238, 245, 0.8) 44.36%);
}

.ant-btn[disabled] {
  background: #e3e3e3 !important;
  border-color: #e3e3e3 !important;
  color: #ffffff !important;
}

.course-header {
  border-radius: 16px 16px 0 0;

  .ant-steps-item.ant-steps-item-finish {
    pointer-events: none;
  }
}

.ssl-main-wrap {
  .ant-spin-nested-loading {
    position: unset;

    .ant-spin-container {
      position: unset;
    }
  }
}

.ant-btn {
  height: 40px;
  border-radius: 30px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  .ant-btn {
    padding: 0 4rem;
  }
}

.ant-input {
  border-radius: 8px;
}

.ant-input {
  height: 40px;
  border: 1.5px solid #e3e3e3;
  color: #212121;
  border-radius: 8px;

  &:hover,
  &:focus {
    border: 1.5px solid #5057d5;
    box-shadow: none;
  }
}

.margin_top textarea.ant-input {

  &:hover,
  &:focus {
    border: 1.5px solid #5057d5;
    box-shadow: none;
  }
}

.ant-form-item-label label {
  color: #212121 !important;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
}

.isMarket-place-price {
  margin: 0 30px;

  .switch-bg_active {
    background: #5057d5;
  }
}

.quill-div_row {
  margin-top: 2rem;

  .ant-col-24 {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .margin_top {
      margin: 0;
      max-width: 49%;
      flex: 0 0 49%;

      &:last-child {
        max-width: 99%;
        flex: 0 0 99%;
      }
    }
  }
}

.marketplace-topic {
  color: #212121;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 4px;
}

.shl-choose-modal-open {
  width: 500px;
}

.ant-dropdown.ant-dropdown-placement-bottomRight {
  ul {
    padding: 1rem;
    border-radius: 8px;

    li {
      // color: #212121;
      font-weight: 600;
      font-size: 16px;
      padding: 1rem;

      &.ant-dropdown-menu-item-active {
        background: #edf2f5;
        border-radius: 8px;
      }
    }
  }
}

.ant-modal.image-popup-show {
  width: 1000px !important;

  .ant-modal-close-x {
    position: absolute;
    right: 5px;
    width: 20px;
    height: 20px;
    top: -8px;
  }
}

.scorm-full-width {
  width: 100%;
  height: 25rem;
  border-width: 1.5px;
  border-color: #e3e3e3;
  border-style: solid;
  border-radius: 8px;
  margin-top: 1.6rem;
}