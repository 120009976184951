@charset "UTF-8";
.ssl-main-wrap {
  background: linear-gradient(106.91deg, #f8f4e8 1.04%, #e9eef5 44.36%);
  overflow: hidden; }
  .ssl-main-wrap .ssl-main-inner {
    background: #fff;
    margin: 3rem;
    border-radius: 10px;
    min-height: calc(100vh - 6rem);
    position: relative; }

.right_wrapper {
  display: flex;
  align-items: center; }
  .right_wrapper .trivia_score {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 5rem;
    border-color: transparent;
    height: 45px;
    margin: 0 1.5rem; }
    .right_wrapper .trivia_score_mobile {
      display: none; }
    .right_wrapper .trivia_score_trophy {
      height: 24px;
      padding: 0 0 0 20px; }
      .right_wrapper .trivia_score_trophy img {
        height: 24px;
        width: 100%; }
    .right_wrapper .trivia_score_title {
      font-size: 16px;
      color: #67686e;
      font-weight: 700;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      padding: 0 5px; }
    .right_wrapper .trivia_score_score {
      font-size: 16px;
      font-weight: 700;
      color: #009444;
      padding: 0 20px 0 0px; }
    .right_wrapper .trivia_score:hover {
      cursor: pointer;
      opacity: 0.7; }
  .right_wrapper .search {
    margin-right: 1.5rem; }

@media (max-width: 840px) {
  .trivia_score_title {
    display: none; }
  .trivia_score_score {
    display: none; }
  .trivia_score_mobile {
    display: block !important;
    text-align: center;
    width: 100px; }
    .trivia_score_mobile .title {
      font-size: 8px;
      color: #67686e;
      font-weight: 550;
      font-style: normal;
      line-height: normal;
      text-transform: uppercase;
      padding: 0 5px; }
    .trivia_score_mobile .score {
      text-align: center;
      letter-spacing: 1px;
      font-size: 14px;
      font-weight: 600;
      color: #009444;
      padding: 0 20px 0 20px; } }

.training_head {
  display: flex;
  justify-content: space-between; }
  .training_head .head__title {
    display: flex;
    align-items: center;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    margin-left: 2.5rem; }
    .training_head .head__title::before {
      content: '•';
      position: absolute;
      font-size: 35px;
      margin-bottom: 0.7rem;
      left: -2.5rem; }
  .training_head .head__action {
    font-weight: bold;
    color: #256bf6;
    cursor: pointer; }
    .training_head .head__action:hover {
      text-decoration: underline;
      opacity: 0.9; }

.red__font .head__title {
  color: #ff0000; }
  .red__font .head__title::before {
    content: '';
    background: red;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid white;
    outline: 1px solid red;
    margin-bottom: 0;
    left: -2rem; }

.training__cards {
  position: relative; }
  .training__cards:not(:last-child)::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 95%;
    height: 1px;
    background: #dadada;
    opacity: 0.5; }

@media (max-width: 840px) {
  .training-wrapper > div {
    flex-wrap: wrap;
    display: block; }
  .section_light > div:nth-child(2) {
    width: 100% !important; } }
