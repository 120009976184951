.existing-card-wrapper {
  .ant-modal-header {
    padding: 4rem 3rem 2rem;
  }
  .ant-modal-title {
    color: #212121;
    font-weight: 500;
    font-size: 20px;
  }
  .ant-modal-close-x {
    svg {
      color: #212121;
    }
  }
}
