.CloneCourseModal {
  .ant-modal-content {
    width: 100%;
    margin: 0 !important;

    span {
      color: black;
      font-size: 16px;
      font-weight: 600;
    }

    .ant-modal-body {
      position: relative;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .wrap-content {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .modal-title {
          padding: 10px 20px;
          width: 100%;

          .title-input {
            margin: 10px 0;
            color: black;
          }

          span {
            color: black;
            font-size: 16px;
            font-weight: 600;
          }
        }

        .modal-body {
          padding: 10px 20px;
          width: 100%;

          .wrapWarning {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 1rem 0;

            .warningIcon {
              font-size: 2rem;
              color: #ffa532;
            }

            label {
              font-size: 1.5rem;
              font-weight: bold;
              margin: 0 1rem;
            }
          }

          .wrap-body-content {
            border: 1px solid #bfbfbf;
            background-color: #efefef;
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 10px 0;
            height: 280px;
            overflow-y: scroll;

            .lesson-box {
              padding: 5px 10px;

              span {
                font-weight: 500 !important;
                color: #444;
              }
            }

            .ant-checkbox-wrapper + .ant-checkbox-wrapper {
              margin: 0 !important;
            }
          }
        }

        .cloning-processing {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;

          .wrap_percent {
            width: 600px;
            padding-top: 20px;

            .ant-progress-outer {
              padding: 0;
            }
          }

          #title {
            font-size: 28px;
          }

          #title-course {
            font-size: 24px;
            padding-top: 10px;
            text-align: center;
          }

          #desc {
            font-size: 14px;
            font-weight: 300;
          }
        }

        .modal-footer {
          width: 100%;
          height: 70px;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-around;
          justify-content: flex-end;
          -webkit-flex-direction: row;
          flex-direction: row;
          -webkit-align-items: center;
          align-items: flex-end;
          border-top: 1px solid #e8e8e8;

          .btn-clone {
            margin-right: 25px;
            border: none;
            border-radius: 25px;
            background-color: #0068ff;
            padding: 0 20px;

            span {
              color: white;
            }
          }

          .btn-cancel {
            border: none;
            width: 90px;
            font-weight: bold;

            &.disabled {
              background-color: transparent !important;

              span {
                color: grey !important;
              }
            }

            span {
              color: #0068ff;
            }
          }
        }
      }
    }

    .ant-modal-header {
      height: 70px;
      display: flex;
      align-items: center;

      .ant-modal-title {
        font-weight: bold;
        font-size: 24px;
      }
    }

    .ant-modal-close {
      top: 10px;
      right: 10px;
      z-index: 10;
      padding: 0;
      color: #444;
    }
  }

  .cloneloading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;

    span {
      font-size: 20px;
      font-weight: 600;
      padding-top: 20px;
    }
  }
}
