.ant {
  &-badge-count {
    box-shadow: none !important;
  }

  &-btn {
    &.rounded {
      border-radius: 5rem;
    }

    &.header-button {
      border-radius: 5rem;
      background-color: white !important;
      color: #0068ff;
      padding-left: 25px;
      padding-right: 25px;
    }

    &.ant-btn--with-icon {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &.ant-btn--secondary {
      background-color: map-get($colors, green) !important;
      border-color: map-get($colors, green) !important;
      font-weight: map-get($typography, h2FontWeight);
    }

    &[disabled] {
      background-color: map-get($colors, darkGreyTwo) !important;
      border-color: map-get($colors, darkGreyTwo) !important;
      color: map-get($colors, white) !important;
    }

    &-primary {
      background-color: map-get($colors, blue);
      border-color: map-get($colors, blue);
    }

    // &-danger {
    //   background-color: map-get($colors, red) !important;
    //   border-color: map-get($colors, red) !important;
    // }

    &-green {
      background-color: map-get($colors, green) !important;
      border-color: map-get($colors, green) !important;
      color: map-get($colors, white) !important;
    }
  }

  &-form {
    font-family: map-get($base, fontFamily), -apple-system, BlinkMacSystemFont,
      'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;

    &-item {
      font-family: map-get($base, fontFamily), -apple-system, BlinkMacSystemFont,
        'Segoe UI', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;

      &-label {
        line-height: initial !important;
        margin-bottom: 0.4rem;

        & label {
          font-weight: 500;
          text-transform: uppercase;
          color: map-get($colors, darkGreyOne) !important;
        }
      }
    }

    label {
      font-size: 1.2rem;
    }
  }

  &-input {
    &.rounded {
      border-radius: 5rem;
    }
  }
}

// .ant-modal-confirm .ant-modal-confirm-btns {
//   margin-top: 0;
// }

.ant-spin-nested-loading > div > .ant-spin.global_ant_spin {
  max-height: initial;
}

// .ant-table-wrapper .ant-spin-nested-loading {
//   min-height: 32rem;
// }

.ant-skeleton-paragraph {
  margin: 0;
  padding: 0;
}

.ant-table {
  overflow-x: auto;
}

.react-tel-input {
  display: flex;
  font-size: 1.4rem !important;
  font-family: inherit !important;

  & input[type='text'],
  & input[type='tel'] {
    height: initial;

    box-sizing: border-box !important;
    margin: 0 !important;
    padding: 0 !important;
    position: relative !important;
    display: inline-block !important;
    width: 100% !important;
    height: 32px !important;
    padding: 4px 11px 4px 50px !important;
    color: #777777 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;

    &:hover,
    &:focus {
      border-color: map-get($colors, blue) !important;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(0, 104, 255, 0.2) !important;
    }
  }

  .flag-dropdown {
    border-radius: 0 !important;
  }

  .selected-flag {
    border-radius: 0 !important;
  }

  .country-list {
    width: 24rem !important;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15) !important;
  }
}

.styled-pagination {
  .ant-pagination {
    color: map-get($colors, blue);

    .ant-pagination-item {
      background-color: map-get($colors, darkGreyThree);
      border-color: map-get($colors, darkGreyThree);
      border-radius: 50%;
      font-weight: bold;
      min-width: 1.8rem;
      height: 1.8rem;
      line-height: 1.6rem;

      a {
        margin: 0;
        height: 100%;
        font-size: 1.1rem;
        color: map-get($colors, darkGreyOne);
      }

      &.ant-pagination-item-active {
        background-color: map-get($colors, blue);
        border-color: map-get($colors, blue);

        a {
          color: map-get($colors, white);
        }
      }
    }
  }

  .ant-pagination-simple {
    .ant-pagination-prev,
    .ant-pagination-next {
      vertical-align: middle !important;
    }

    .ant-pagination-simple-pager input {
      pointer-events: none !important;
      border: none !important;
      padding: 0 !important;
    }
  }
}

.ant-btn-background-ghost.ant-btn-primary {
  color: map-get($colors, blue);
  background: transparent !important;
  border-color: #0068ff !important;
  text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
