.shl-channel-form-videos-conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  height: 100%;

  .shl-channel-form-alert {
    width: 100%;
  }

  .shl-channel-form-videos {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
  }
}

.shl-channel-form-video {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  gap: 1rem;
  background: #f8f8f8;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 2rem;

  &-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  border-radius: 0.5rem;
  &__thumbnail {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background: #000000;
    position: relative;
    object-fit: cover;

    .react-player {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__title {
    font-weight: bold;
    word-break: break-all;
  }

  &__action {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    .preview {
      color: blue;
      border-radius: 25px;
    }

    .delete {
      color: red;
      border-radius: 25px;
    }

    span {
      padding: 1rem 2rem;
      background: #e4e8f5dc;
      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      text-align: center;

      &:hover {
        box-shadow: 0 0 1px 1px #000;
      }
    }
  }
}
