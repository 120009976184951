.button-create {
  z-index: 1;
  top: 0;
  right: 0;
  position: absolute;
  float: right;

  &:hover {
    background: #1dba61;
    color: white;
  }
}

.ant-tabs {
  width: 100%;
  overflow: initial;
}

.ant-modal-close-x {
  position: absolute;
  right: 0;
  color: #212121;
  font-size: 18px;
  i {
    font-size: 16px;
  }
}

.tab-nav-margin .ant-tabs-bar {
  margin-bottom: 0px;
}

.custom-ant-modal .ant-modal-content {
  border-radius: 10px;

  .ant-modal-header {
    border-bottom: 0px solid #e8e8e8;
  }

  .ant-modal-body {
    padding: 0px 24px;
    position: relative;
  }

  .ant-modal-footer {
    padding: 0px 16px;
    border-top: none;
  }
}

.bg-modal-left {
  margin: 0px -44px;
  padding: 0px 44px;

  .card__content {
    padding: 1.5rem 2rem;
    background: transparent;
  }
}
