.date_time_error {
  margin-bottom: 40px;
  max-width: 800px;
}

.ant-modal-content {
  border-radius: 1.2rem;
}

.ant-modal-header {
  border-radius: 1rem;
}

.ant-modal-footer {
  border-radius: 1rem;
}

.ant-modal-title {
  color: #212121;
  font-weight: bold;
}
