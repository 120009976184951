.upload-document {
  padding: 24px;

  &__header {
    h1 {
      color: #333;
      font-size: 18px;
      font-weight: 600;
    }

    p {
      color: #666;
      font-size: 14px;
    }
  }

  &__input-selector {
    margin-bottom: 24px;

    &-radio {
      label {
        &:first-child {
          border-radius: 20px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          border-radius: 20px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.ant-radio-button-wrapper-checked {
          background: #5057d5 !important;
        }
      }
    }
  }

  &__dropzone-container {
    width: 100%;
    cursor: pointer;
  }

  &__dropzone {
    border: 2px dashed #d9d9d9;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    transition: border-color 0.3s;

    &.active {
      border-color: #1890ff;
    }
  }

  &__upload-content {
    .anticon {
      font-size: 48px;
      color: #999;
      margin-bottom: 16px;
    }
  }

  &__text {
    font-size: 16px;
    color: #666;
    margin-bottom: 8px;
  }

  &__hint {
    font-size: 14px;
    color: #999;
  }

  &__file-preview {
    margin: 16px 0;

    .file-item {
      display: flex;
      align-items: center;
      padding: 8px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;

      .file-icon {
        font-size: 24px;
        color: #1890ff;
        margin-right: 8px;
      }

      .file-name {
        flex: 1;
        cursor: pointer;
        color: #1890ff;

        &:hover {
          text-decoration: underline;
        }
      }

      .remove-button {
        color: #ff4d4f;

        &:hover {
          color: #ff7875;
        }
      }
    }
  }

  &__text-actions {
    margin-top: 16px;
    display: flex;
    gap: 8px;
  }

  &__confirm-button {
    &[disabled] {
      &.ant-btn-primary {
        background-color: #f5f5f5;
        border-color: #d9d9d9;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__edit-button {
    margin-left: 8px;
  }

  &__text-input {
    margin: 16px 0;
  }

  &__textarea {
    width: 100%;
    min-height: 200px;
    resize: vertical;
  }

  &__button {
    margin-top: 16px;
  }
}
