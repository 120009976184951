.wrap-lesson-info {
  display: flex;
  align-items: center;
  align-self: flex-start;
}

.card__wrapper{
  position: absolute;
  text-align: 0;
  top: 25px;
  right: 0;
}