.event__mp__top {
  width: 100%;
}

.event__mp__bottom {
  width: 100%;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.flex__row {
  display: flex;
  flex-direction: row;
}

.timeline {
  padding: 15px 10px 0 20px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 20px;

  .title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .ant-time-picker-panel-inner {
    width: 100%;
  }
}

.marketplace__checkbox span {
  font-size: 14px;
}
