.MyCalendar {
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 19rem);
  flex-wrap: wrap;
  gap: 2rem;

  .day-calendar {
    height: 100%;
    flex: 0.4;
    .rbc-allday-cell {
      display: none !important;
    }

    .rbc-toolbar {
      margin-bottom: 0;
      border: 1px solid #ddd;
      border-bottom: none;
      padding: 1rem 0;
      color: #2d6ff2;
      text-transform: uppercase;
      font-weight: bold;

      &-label {
        text-align: left;
      }
    }

    .rbc-time-content {
      border-top: 1px solid #ddd;
    }

    .rbc-time-view {
      border-top: none;
    }

    .rbc-events-container {
      pointer-events: none;
      margin-right: 0;
    }

    .rbc-event {
      pointer-events: all;
    }

    .rbc-label {
      font-weight: bold;
    }

    .rbc-day-slot {
      .rbc-time-slot {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: #5385e972;
        }
      }
    }
  }

  .rbc-btn-group {
    display: none;
    color: #f5f5f5d8;
  }

  .month-calendar {
    flex: 1;

    .rbc-month-view {
      border: none;

      .rbc-show-more {
        display: none;
      }
    }

    .rbc-event {
      background-color: transparent;
    }

    .dayInMonth {
      height: 100%;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
      margin-right: 1rem;
    }

    .rbc-date-cell {
      position: relative;
      .redDot {
        &::before {
          content: '';
          height: 10px;
          width: 10px;
          background-color: red;
          border-radius: 50%;
          position: absolute;
          left: 15px;
          top: 10px;
        }
      }
    }

    .sameMonthFont {
      color: #3b5a97;
      font-size: 22px;
    }

    .white-text {
      color: white;
    }

    .calendar-day-header {
      padding-top: 2rem;
      padding-bottom: 1rem;
      text-transform: uppercase;
    }

    .datepick-container {
      display: flex;
      align-items: center;
      font-weight: bold;
      border-bottom: 1px solid #f0f0f0;
      .month-picker {
        width: 14rem;
        margin-bottom: 1rem;
        margin-left: 1rem;
      }
      .datepick-text {
        flex: 1;
        display: flex;
        justify-content: center;
      }

      .datepick-question {
        color: #3c71ea;
        font-size: 2rem;
        cursor: pointer;
      }
    }
  }

  .rbc-day-bg {
    cursor: pointer;
    border-left: none;
    &:not(:first-child) {
      border-left: 1rem solid white;
    }

    &:hover {
      margin-left: 0.75rem;
      border: 0.25rem solid #2d6ff2;
    }
  }

  .rbc-month-row {
    border-top: 1rem solid white;
  }
  .rbc-header {
    border-bottom: 1rem solid white;
    border-left: 1rem solid white;
  }

  .day-calendar {
    .rbc-event,
    .rbc-day-slot .rbc-background-event {
      background-color: #2d6ff2;

      &:hover {
        background-color: #001849f1;
      }
    }
  }
}
.calendar-title-container {
  .title {
    margin-top: 2rem;
  }

  label {
    font-size: 14px;
    font-weight: bold;
  }

  // .remove-schedule-button {
  //   margin-top: 2rem;
  //   width: 100%;
  // }
  .coaching-checkbox {
    font-weight: bold;
  }
}

.calendar-footer {
  background: #f7f7f7;
  border: 1px solid #ddd;
  width: 100%;
  padding: 1rem 4rem;
  display: flex;
  justify-content: flex-end;
}

.shl-calendar-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
}

.calendar-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // padding: 0 2rem;
  background: #fff;
  gap: 1rem;
  // margin-top: 0.5rem;
  height: 5rem;
  svg {
    stroke-width: 2px;
    border-radius: 2px;
  }

  span {
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    font-size: 2rem;
  }
}

.duration-time {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: bold;
}

.card-title-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar-modal-footer {
  margin: 1rem 0;
}

.calendar-legend-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  .item {
    display: flex;
    gap: 1rem;

    &-text {
      display: flex;
      flex-direction: column;

      p {
        font-size: 0.9em;
      }
    }

    .box {
      height: 10px;
      width: 10px;
      margin-top: 6px;
    }

    span {
      white-space: nowrap;
    }

    .green {
      background-color: #14b314;
    }

    .teal {
      background-color: #cddeff;
    }

    .red {
      background-color: red;
      border-radius: 50%;
    }

    .blue {
      background-color: blue;
    }
  }
}
