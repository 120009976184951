.marketplace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -30px;

  .button__marketplace {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 3rem;
    border: 1px solid #e3e3e3;
    border-radius: 16px;
    cursor: pointer;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }
  .marketplace-head {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
}
