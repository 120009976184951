@import '../../../../styles/variables';

.media-card {
  border: 1px solid #ddd;
  overflow: hidden;
  &__inner {
  }

  &__thumb {
    position: relative;
    margin: -1px -1px 0 -1px;
    padding-bottom: 56.25%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border-bottom: 1px solid #ddd;
    &:hover {
      cursor: pointer;
    }

    img {
      position: absolute;

      height: 100%;
      width: 100%;
    }

    .fileThumb {
      object-fit: contain;
      background: #ddd;
    }

    .imageThumb {
      object-fit: cover;
    }
  }

  &__content {
    padding: 1.5rem 2rem;
    position: relative;

    > label {
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }

  &__format {
    display: flex;
    justify-content: space-between;

    > span {
      position: relative;
      display: inline-flex;
      padding: 0.4rem 1rem;
      font-size: 1rem;
      background: #ececec;
      border-radius: 2rem;
      text-transform: uppercase;

      &::after {
        content: attr(data-ext);

        position: absolute;
        left: 100%;
        margin-left: 1rem;
        width: 150px;
      }
    }
  }

  &__title {
    font-weight: 600;
    color: map-get($colors, blue);

    margin: 1rem 0;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }
  }

  &__date-container {
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;

    > span {
      width: 50%;
    }
  }

  &__ai-enable-container {
    font-size: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    span {
      flex-wrap: nowrap;
    }
  }

  &__duration {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    border-radius: 2rem;

    padding: 0.4rem 1.2rem;
    font-size: 1.2rem;

    background: map-get($colors, black);
    color: map-get($colors, white);
  }

  .checkbox-container {
    display: none;
  }

  &:hover {
    .checkbox-container {
      display: initial;
    }
  }

  &.is-checked {
    .checkbox-container {
      display: initial;
    }
  }
}

.media-card-status {
  color: #0068ff;
  text-align: right;
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;

  &.make-private {
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }

  .label {
    display: initial;
    margin-left: 5px;
  }

  .loading-media {
    display: none;
    width: 15px;
    height: 15px;
    position: relative;
    float: right;

    .double-bounce1,
    .double-bounce2 {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #0068ff;
      opacity: 0.6;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out;
    }

    .double-bounce2 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
  }

  &.is-loading {
    .loading-media {
      display: initial;
    }

    .label {
      display: none;
    }
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.disabled-checkbox > .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #858585 !important;
}

.disabled-checkbox
  > .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: white;
}

.blue-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0068ff;
  border-color: #0068ff;
}

.blue-checkbox
  > .ant-checkbox-disabled.ant-checkbox-checked
  .ant-checkbox-inner::after {
  border-color: white;
}
