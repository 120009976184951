.fullscreen-modal {
  .ant-modal {
    max-width: 100vw !important;
    width: 100vw !important;
    padding: 0;
    top: 0;
  }

  .ant-modal-content {
    height: 100vh;
    border-radius: 0;
    background: #ffffff5d;
  }

  .ant-modal-body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #8383833b;
  }

  .ant-modal-close {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 1001;

    .ant-modal-close-x {
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      line-height: 32px;
      color: #000;
    }
  }
}

.image-preview-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.shl-tools {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  z-index: 1000;

  button {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }

    .anticon {
      color: #000;
    }
  }
}

/* Add these styles to ensure proper centering of the transform wrapper */
.react-transform-wrapper {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.react-transform-component {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
